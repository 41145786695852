import React, { useEffect, useState } from 'react';
import './PaperOneByTopicId.css'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useNavigate } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'

import { GetSubjectTopics } from "../MYAPI";
import Payment from './Payment';

function PaperOneByTopicId(props){
    const [topicsDtos, setTopicsDtos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [requestPayment, setRequestPayment] = useState(false);

    const { instance, accounts } = useMsal();   
    const navigate = useNavigate();

    let leftIcon = <FontAwesomeIcon style={{color: "dodgerblue", fontSize: "1.1em" }} icon={faArrowRight}  />

    useEffect(() => {
        setTopicsDtos([]);
        const request = {
            ...loginRequest, 
            account: accounts[0]
        };
        setLoading(true)
        instance.acquireTokenSilent(request).then((response) => {
            
            GetSubjectTopics(props.subjectId ,response.accessToken, response.idTokenClaims.sub, (res) => {
                //console.log("topicsDtos: ", res);
                setTopicsDtos(res)
            })
        });
    }, [loading])

    function handleLoadMCQByTopidId(topicdto) {
        //console.log("topicTo View: ", topicdto);
        setRequestPayment(false)
        
        if(props.status.toLowerCase() === 'paid' || props.status === "OBC"){
            navigate(`/topic/mcq/${topicdto.topicNumber}/${topicdto.title}`)
        }else{
            //payment is required
            //setTitle(paper.subjectTitle)

            setRequestPayment(true);            
            
            //setModalShow(true)
            
        }
    }
    function handleClose(){
        setRequestPayment(false)
    }

    return(
        <div className="p-container">
            {topicsDtos && topicsDtos.length > 0 ? <>
            {topicsDtos.map((topicdto) => {
                return(
                    <div key={topicdto.id}>
                            <div onClick={() => handleLoadMCQByTopidId(topicdto)} style={{padding: "10px", display: "grid", gridTemplateColumns: "95% 5%", cursor: "pointer"}}>
                            <div style={{float: "left"}}>
                                <b>{topicdto.title}</b><br/>
                                {topicdto.totalQuestionCount} Questions Found <br/>
                                Answered: <span style={{color: "green"}}>{topicdto.passedQuestionCount} </span>, UnAnswered: <span style={{color: "red"}}> {topicdto.totalQuestionCount - topicdto.passedQuestionCount} </span>, Coverage: {topicdto.percentageCoverage}%
                            </div>

                            <div style={{float: "right", verticalAlign: "middle"}}>
                                {leftIcon}
                            </div>

                            <span style={{clear: "both"}}></span>
                        </div>

                    </div>
                )
            })}
            </> : <>Loading...</>}

            {requestPayment? <> <Payment showModal={requestPayment} handleClose={handleClose} subjectId={props.subjectId} title={props.title}  /> </>: <></>}
            
        </div>
    )
}

export default PaperOneByTopicId;