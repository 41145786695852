import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import {loginRequest} from "../authConfig"
import { getUserSubjectEnrollments, UnEnrollment } from "../MYAPI";
import Spinner from 'react-bootstrap/Spinner';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import userEvent from "@testing-library/user-event";


function StudyIndex(){
    const [loading, setLoading] = useState(false);
    const [subjects, setSubjects] = useState(null);
    const [subjectSelected, setSubjectSelected] = useState(null);

    const { instance, accounts, inProgress } = useMsal();
    //const [accessToken, setAccessToken] = useState(null);

    const navigate = useNavigate();

    //Modal popup
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    
    function handleShow(mysubjectSelected){
        setSubjectSelected(mysubjectSelected);
        
        setShow(true);
    }

    //const name = accounts[0] && accounts[0].name;

    function UnEnroll(subjectId){
        
        for(var i = 0; i<subjects.length; i++){
            if(subjects[i].subjectId == subjectId){
                subjects[i].isLoading = true;
            }
        }

        const request = {
            ...loginRequest, 
            account: accounts[0]
        };
        
        instance.acquireTokenSilent(request).then((response) => {
            
            UnEnrollment(subjectId, response.accessToken, (res) => {
                setSubjects(res);
                //console.log("User Subjects: ", res)
                setLoading(false);
            } )
        })
        setShow(false);
    }
   
    useEffect(() => {       
        setLoading(true);
        //const accessToken = localStorage.getItem('accessToken');
        //console.log("accessToken from localstorage: ", accessToken)
        const request = {
            ...loginRequest, 
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {
            //setAccessToken(response.accesToken);
            console.clear();
            //console.log("access Token response: ", response);
            //console.log("userId:", response.idTokenClaims.sub);
           getUserSubjectEnrollments(response.accessToken, response.idTokenClaims.sub, (res) => {
                setSubjects(res);
                //console.log("User Subjects: ", res)
                localStorage.setItem('usersubjects', JSON.stringify(res));
                setLoading(false);
          })                
        })
                
    }, []);


    useEffect(() => {

    }, [subjects]);



    const myStyle = {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
    }
    const mySubjectStyle = {
        width: "300px",
        height: "150px",
        borderRadius: "0.3em",
        //margin: "8px",
        padding: "1px",
        display: "flex",
        flexWrap: "nowrap"
    };
    const horizontalDiv = {
        width: "99%",
        margin: "0 auto",
    }

    const handleGetPastPapers = (mysubject) => {
        //console.log("mysubject:", mysubject)
        localStorage.setItem('onemonth', JSON.stringify(mysubject.monthlyPrice))
        localStorage.setItem('threemonth', JSON.stringify(mysubject.annualPrice))
        localStorage.setItem('tenmonth', JSON.stringify(mysubject.tenMonthsPrice))
        localStorage.setItem('subjectTitle', JSON.stringify(mysubject.subjectTitle));
        navigate(`/subject-study-detail/${mysubject.subjectId}`)
    }

    return(
        <div>
            {loading ? <> <div style={{position: "absolute", top: "50%", left: "50%"}}>
            <div>
                <Spinner animation="border" variant="primary" />Loading...
            </div>
                </div> </>: <>

            {subjects && subjects.length > 0 ? <>
            <div style={myStyle}>
                {subjects.map((mysubject) => {
                    return(
                        
                        <div key={mysubject.subjectId} style={{border: "1px solid gray", margin: "10px", borderRadius: "0.2em", cursor: "pointer"}} >

                            {/**
                             * Modal display follows
                             */}



                            <div style={mySubjectStyle}>
                                {/*<Link to={`/subject-study-detail/${mysubject.subjectId}`} style={{textDecoration: "none",float: "left", width: "30%"}}>
                                    
                                </Link>*/}
                                <div style={{textDecoration: "none",float: "left", width: "30%"}}>
                                <div onClick={() => handleGetPastPapers(mysubject)}>
                                        <img style={{backgroundColor: "red"}} src={mysubject.imageUrl} width="96" height="100" />
                                </div> 
                                </div>
                                

                                {/**
                                 * <Link  style={{float: "left", width: "60%", paddingTop: "30px", paddingLeft: "6px", textDecoration: "none"}} to={`/subject-study-detail/${mysubject.subjectId}`} >
                                    
                                </Link>
                                 * 
                                 */}
                                
                                <div onClick={() => handleGetPastPapers(mysubject)}  style={{float: "left", width: "60%", paddingTop: "30px", paddingLeft: "6px", textDecoration: "none"}}>
                                        <span style={{margin: "4px", fontWeight: "bold"}}>{mysubject.subjectTitle}</span>
                                        {mysubject.isLoading? <div style={{display: "flex", justifyContent: "center",alignItems: "center"}}>
                                            <Spinner animation="border" variant="primary" />
                                        </div>:<> </>}
                                </div>


                                <div style={{float: "left", width: "10%"}}>
                                    <button onClick={() => handleShow(mysubject)}
                                        style={{border: "0", backgroundColor: "transparent", fontWeight: "bold", padding:"8px", position: "relative", top: "-8px"}}>...</button>
                                </div>
                                <br style={{clear: "left"}} />
                            </div>

                            <div style={{backgroundColor: "dodgerblue", padding: "10px", textAlign: "center" }} onClick={() => handleGetPastPapers(mysubject)}>
                                <span style={{color: "yellow"}}>Coverage: {mysubject.averageProgress}%  Complete</span>
                            </div>

                            {/**<Link to={`/subject-study-detail/${mysubject.subjectId}`} style={{textDecoration: "none"}}>
                                
                            </Link>  */}

                                                                               
                            
                        </div>
                        
                    )
                })}
                
            </div>

            {subjectSelected != null ? <>
                <Modal show={show} onHide={handleClose} animation={false} size='sm' >
                                <Modal.Header closeButton>
                                <Modal.Title>OgaBook</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Do you want to UnEnroll from <b>{subjectSelected.subjectTitle}</b>? Feel free! This action is reversible by Re-Enrolling again! </Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    NO
                                </Button>
                                <Button variant="danger" onClick={() => UnEnroll(subjectSelected.subjectId)}>
                                    YES
                                </Button>
                                </Modal.Footer>
                            </Modal>
            </> : <></>}
            
            </> : <>
                No Enrolments yet
            </> }
            </>}
        </div>
    )
}

export default StudyIndex;