import React, {useState, useEffect} from 'react';
import './mcq.css';
import parse from 'html-react-parser';

function Mcq(props) {
    
    

   const m = props.mcq;
    return(
        <div className='question-item'>
            
            <div className='mcq-instruction'> {parse(m.instruction)} </div>
                {m.questionImageUrl ? <> <p> <img className='question-image' src={m.questionImageUrl} height='180' alt='Instruction Image failed to load' /> </p>
                </> : <></>}
                {/** QuestionText and ExamYear */}
                <div className='mcq-item'> <div>{parse(m.question)} {m.examYear? <>
                    <i style={{"fontSize": "0.6em"}}>      ( Exam Year: {m.examYear} )</i>
                </>: <></>} </div>  
                </div>

                {/** SUBMITTED ANSWERS */}

            {m.isAnswerSubmitted  ? <>
                
                <div>
                {
                 m.mcqOptions && m.mcqOptions.map((o) => {
                     return(
                         <div key={o.id.toString()}>
                             {m.answerStatus === "correct" ? 
                             <>
                                {o.optionSelectedValue? 
                                <>
                                    <label className='mcq-content'>
                                        <div>
                                            <label className='container-m' style={{"color": "green", fontWeight: "bold"}}> 
                                                                                    
                                                {parse(o.option)} 
                                                <input type='checkbox' name={m.id} id={o.id} value={false} defaultChecked={o.optionSelectedValue} />
                                                <span className='checkmark'></span>
                                            </label>
                                        </div>
                                    </label>
                                </>: <>
                                    <label className='mcq-content'>
                                        <div>
                                            <label className='container-m'> 
                                                                                    
                                                {parse(o.option)} 
                                                <input type='checkbox' name={m.id} id={o.id} value={false} defaultChecked={o.optionSelectedValue} />
                                                <span className='checkmark'></span>
                                            </label>
                                        </div>
                                    </label>
                                </>}
                             
                             </>:<>

                             {m.answerStatus === "wrong" ? 
                             <>
                                {o.optionSelectedValue? 
                                <>
                                    <label className='mcq-content'>
                                        <div>
                                            <label className='container-m' style={{"color": "red"}}>                                            
                                                {parse(o.option)}
                                                <input type='checkbox' name={m.id} id={o.id} value={false} defaultChecked={o.optionSelectedValue} />
                                                <span className='checkmark'></span>
                                            </label>
                                        </div>
                                    </label>
                                </> : <>
                                    <label className='mcq-content'>
                                        <div>
                                            <label className='container-m' >  
                                                {parse(o.option)}
                                                <input type='checkbox' name={m.id} id={o.id} value={false} defaultChecked={o.optionSelectedValue} />
                                                <span className='checkmark'></span>
                                            </label>
                                        </div>
                                    </label>
                                </>}
                                
                             
                             </>:<>
                             
                             
                             </>}

                             {m.answerStatus == "unanswered" ? 
                             <>                             
                                <label className='mcq-content'>
                                    <div>
                                        <label className='container-m'>
                                        
                                        {parse(o.option)}
                                            <input type='checkbox' name={m.id} id={o.id} value={false} defaultChecked={o.optionSelectedValue} />
                                            <span className='checkmark'></span>
                                        </label>
                                    </div>
                                </label>
                             </>: <></>}

                             </>}
                         </div>
                     )
                 })
                 }

                 {
                 m.mcqOptionImages && m.mcqOptionImages.map((o) => {
                     return(
                         <div key={o.id.toString()}>
                             {m.answerStatus == "correct" ? <>
                             {o.optionSelectedValue? <>
                                <label className='mcq-content'>
                                    <div>
                                        <label className='container-m' style={{"color": "green"}}> 
                                            <span style={{"float":"left","color":"yellow", paddingRight: "6px", paddingLeft: "6px", marginRight:"8px"}}> &#x2705; </span>
                                            <img src={o.optionImageUrl} height='70' />
                                            <input type='checkbox' name={m.id} id={o.id} value={false} defaultChecked={o.optionSelectedValue} />
                                            <span className='checkmark'></span>
                                        </label>
                                    </div>
                                </label>
                             </> : <>
                                <label className='mcq-content'>
                                    <div>
                                        <label className='container-m' style={{"color": "green"}}>
                                         
                                            <img src={o.optionImageUrl} height='70' />
                                            <input type='checkbox' name={m.id} id={o.id} value={false} defaultChecked={o.optionSelectedValue} />
                                            <span className='checkmark'></span>
                                        </label>
                                    </div>
                                </label>
                             </>}
                             

                             </>:<>
                             {m.answerStatus == "wrong" ? 
                             <>
                                {o.optionSelectedValue ? 
                                <>
                                    <label className='mcq-content'>
                                        <div>
                                            <label className='container-m' style={{"color": "red"}}>
                                            <span style={{"float":"left","color":"yellow", paddingRight: "6px", paddingLeft: "6px", marginRight:"8px"}}> &#x274C; </span>
                                                <img src={o.optionImageUrl} height='70' />
                                                <input type='checkbox' name={m.id} id={o.id} value={false} defaultChecked={o.optionSelectedValue} />
                                                <span className='checkmark'></span>
                                            </label>
                                        </div>
                                    </label>
                                </> : <>
                                    <label className='mcq-content'>
                                        <div>
                                            <label className='container-m'>
                                                <img src={o.optionImageUrl} height='70' />
                                                <input type='checkbox' name={m.id} id={o.id} value={false} defaultChecked={o.optionSelectedValue} />
                                                <span className='checkmark'></span>
                                            </label>
                                        </div>
                                    </label>
                                </>}
                                
                            </>:<>
                                
                             </>}
                             {m.answerStatus == "unanswered" ? <>
                                    <label className='mcq-content'>
                                        <div>
                                            <label className='container-m'>
                                                <img src={o.optionImageUrl} height='70' />
                                                <input type='checkbox' name={m.id} id={o.id} value={false} defaultChecked={o.optionSelectedValue} />
                                                <span className='checkmark'></span>
                                            </label>
                                        </div>
                                    </label>
                             </> : <></>}

                             </>}
                             <hr />
                         </div>
                     )
                 })
                 }
                 <br/>
                </div>

                 <div>
                     {m.answerStatus == "correct"?
                     <>
                        <div style={{marginTop: '25px'}}>
                        <span style={{"float":"left","color":"green", "margin": "-20px"}}>
                        &#x2705; Correct </span>
                        
                        <button type='button' style={{"backgroundColor":"#d4ecff", "padding": "5px", "marginTop": "-30px", "border": "1px solid #2196F3", "borderRadius": "5px", "float":"right"}} onClick={() => props.showExplanationFxn(m)} >Explanation</button>
                        </div>
                    </>:<>
                        <div style={{marginTop: '25px'}}>
                        <span style={{"float":"left","color":"red", "margin": "-20px"}}>
                        &#10060; Failed </span>
                        
                        <button type='button' style={{"backgroundColor":"#d4ecff", "padding": "5px", "marginTop": "-30px", "border": "1px solid #2196F3", "borderRadius": "5px", "float":"right"}}  onClick={() => props.showExplanationFxn(m)} >Explanation</button>
                        </div>
                     </>}
                     <br/>
                     {m.showExplanation ? <>
                        <div style={{"backgroundColor": "#d4ecff", "padding":"5px", "marginTop": "-30px", "border":"1px solid #2196F3", "borderRadius": "5px", "clear": "both"}}>
                        <div>
                            <i style={{"color": "gray"}}>
                                <b>Answer: {props.getCorrectionAnwerList(m)}</b>
                            </i><br/><br/>
                            <span style={{"color": "green"}}>
                                <b>Justification:</b>
                            </span>
                        </div>
                        
                        <div>
                            {m.justificationImageUrl ? <> <img src={m.justificationImageUrl} width='300' height='200' />
                            </>:<></>}
                            {parse(m.justificationText)}
                        </div>
                        <div>
                            Got a different Answer? <button style={{"border": "0px", "color":"blue"}} type='button' onClick={() => props.sendReportFn(m)}><b>Report Here!</b> </button>                        
                        </div>
                     </div>
                     </> : <></>}
                     
                 </div>

               
                </>: <>
                {/*ANSWERS NOT SUBMITTED*/}
                {
                    m.mcqOptions && m.mcqOptions.map((option) => {
                        return(
                            <div className='mcq-content' key={option.id}>
                                <div>
                                    <label className='container-m'>
                                    {parse(option.option)}
                                    <input type='checkbox' name={m.id} id={option.id} onChange={() => props.updateMcqList(m, option)} defaultChecked={option.optionSelectedValue} value={false} /> 
                                    <span className='checkmark'></span>
                                    </label>
                                </div>
                            </div>
                        )
                    })
                } 
                {
                    m.mcqOptionImages && m.mcqOptionImages.map((option) => {
                        return(
                            <div className='mcq-content' key={option.id}>
                                <div>
                                    <label className='container-m'>
                                    <img src={option.optionImageUrl} height='70' />
                                    <input type='checkbox' name={m.id} id={option.id} onChange={() => props.updateMcqImgList(m, option)} defaultChecked={option.optionSelectedValue} value={false} /> 
                                    <span className='checkmark'></span>
                                    </label>
                                    <hr />
                                </div>
                            </div>
                        )
                    })
                }
                </>
            }
        </div>
    );
}
export default Mcq;
