import React from "react";
import Lottie from "react-lottie-player";
import constructionMen from '../asserts/77148-construction-men-at-work.json'

function BookIndex(){


    return(
        <div style={{maxWidth: "760px", margin: "auto", backgroundColor: "whitesmoke", padding: "0 10px 10px 10px"}}>
            <Lottie loop={true} play={true} animationData={constructionMen}/>
            <h2 style={{textAlign: "center"}}>Under Construction</h2>
        </div>
    )
}


export default BookIndex;