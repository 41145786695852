//import { paste } from '@testing-library/user-event/dist/paste';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import './payment.css'
import { InputGroup } from 'react-bootstrap';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Spinner from 'react-bootstrap/Spinner';

import { ForwardSubjectPayment, GetSuccessfulPaymentsFromBackend, GetPaperOnePastPapers, UpdateSuccessFromBackEnd } from "../MYAPI";


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAudioDescription, faBasketShopping} from '@fortawesome/free-solid-svg-icons'
import ValidateUserPayment from '../ValidateUserPayment';

function PaperOnepastpapersPage(props){
    const [papers, setPapers] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [title, setTitle] = useState('');
    const [durationS, setDuration] = useState(0);
    const [priceS, setPrice] = useState(0);
    const [selectedPayment, setSelectedPayment] = useState("option1")
    const [nextSelectedPay, setNextSelectedPay] = useState('option4');
    const [isNext, setIsNext] = useState(false);
    const [isOMSelect, setIsOMSelect] = useState(false);
   
    const [oneMonth, setOnMonth] = useState(0);
    const [threeMonths, setThreeMonths] = useState(0);
    const [tenMonths, setTenMonths] = useState(0);
    const [amout, setAmount] = useState(0);
    const [phone, setPhone] = useState('');
    const [isError, setIsError] = useState(false);
    const [isCorrect, setIscorrect] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isWaitingPaymentConfirmation, setIsWaitingPaymentConfirmation] = useState(false);
    const [ussd, setUssd] = useState('');
    const [action, setAction] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [isPaymentDone, setIsPaymentDone] = useState(false)
    const [transaction_reason, setTransactionReason] = useState('');
    const [transaction_msg, setTransactionMsg] = useState('');
    const [stoptimer, setStopTimer] = useState('');
    const [paymentReceivedSuccessfully, setPaymentReceivedSuccess] = useState(false);
    

    const { instance, accounts } = useMsal();
    

    const navigate = useNavigate();

    let { paperOnes, isLoading } = props;

    let salesIcon = <FontAwesomeIcon style={{color: "yellow", fontSize: "1.1em" }} icon={faBasketShopping}  /> 

    useEffect(() => {
        const request = {
            ...loginRequest, 
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {
            UpdateSuccessFromBackEnd(response.accessToken, (res) => {
                //console.log("Here we go")
            })
        });
    }, [])

    useEffect(() => {
        
        setOnMonth(JSON.parse(localStorage.getItem('onemonth')));
        setThreeMonths(localStorage.getItem('threemonth'));
        setTenMonths(localStorage.getItem('tenmonth'));
        setAmount(oneMonth);
        
        setPapers(paperOnes);
        

        
    })
    useEffect(() => {

    }, [amout])

    useEffect(() => {
        
    }, [papers])

    const mystyle = {
        //maxWidth: "380px",
        backgroundColor: "yellow",
        padding: "10px",
        margin: "10px",
        //border: "1px solid green",
        borderRadius: "1.6em 1%",
        minHeight: "95px",
        backgroundImage: "linear-gradient(to right, #0C3F8A, #EFF4FC )"
    }

    
    function handleLoadMcq(paper){
        var pastpapers = papers;
        setPapers(null);
        setPapers(pastpapers)

        setAction('');
        setIsNext(false);
        setTransactionMsg('');
        setTransactionReason();
        setUssd('');
        setIsPaymentDone(false);
        setIsWaitingPaymentConfirmation(false);
        setIscorrect(false)
        

        //console.log("paper selected status: ", paper.status);

        if(paper.status === 'Free' || paper.status === 'Basic' || paper.status === 'Paid' || paper.status === "OBC"){
            navigate(`/pastpaper/mcq/${paper.id}/${paper.title}`)
        }else{
            //payment is required
            setTitle(paper.subjectTitle)
            
            
            setModalShow(true)
            
        }
        
    }
    const handleClose = () => {
        setLoading(false);
        setPaymentReceivedSuccess(false)
        
        for(var i = 0; i<papers.length; i++){
            papers[i].isLoading = false;
        }
        setIsWaitingPaymentConfirmation(false)
        setModalShow(false);
        clearInterval(stoptimer);
        if(action === "SUCCESS"){

            GetPaperOnePastPapers(papers[0].subjectId, (res)=> {
                setPapers(res);
                
                setLoading(false);
            });
        }
    } 
    const handleShow = () => {
        setLoading(false)
        setModalShow(true)
    };
    
    const removeBtnStyle = {
        border: 0,
        backgroundColor: "transparent",
        cursor: "pointer"
        
    }
    function handleOptionChange(event){
        setSelectedPayment(event.target.value);
    }
    function handleMoveNext(){
        if(selectedPayment==='option1'){
            setAmount(localStorage.getItem('onemonth'))
        }else if(selectedPayment==='option2'){
            setAmount(localStorage.getItem('threemonth'))
        }else{
            setAmount(localStorage.getItem('tenmonth'));
        }
        setIsNext(true)

        //console.log("paper --> subjectId: ", papers)
    }

    function handleBackToPrev(){
        setIsNext(false)
    }

    function handleFinalOptionChange(event){
        setNextSelectedPay(event.target.value);
        if(event.target.value === "option4"){
            setIsOMSelect(false);
        }else{
            setIsOMSelect(true)
        }
    }

    function handlePhoneChange(event){
        setPhone(event.target.value);
        if(event.target.value.length === 9){
            setIscorrect(false)
        }else{
            setIscorrect(true);
        }
    }
 
    function handleSubmitPayment(){    
        var subjectId = 0;
        var price = 0;
        var duration = 30;
        setAction('');
        
        setTransactionMsg('');
        setTransactionReason();
        setUssd('');
        setIsPaymentDone(false);
        setIsWaitingPaymentConfirmation(false);
        setIscorrect(false);


        setLoading(true);
        

        if(selectedPayment==='option1'){
            price = oneMonth;
            duration = 30;
            setPrice(oneMonth)
            setDuration(30)
        }else if(selectedPayment==='option2'){
            price = threeMonths;
            setPrice(threeMonths)
            duration = 90
            setDuration(90)
        }else if(selectedPayment==='option3'){
            price = tenMonths;
            setDuration(300);
            setPrice(tenMonths)
            duration = 300;
        }

        subjectId = papers[0].subjectId;

        setIscorrect(true);
        const request = {
            ...loginRequest, 
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {
            
            setAccessToken(response.accessToken)

            //User should validate payment by entering pin
            ForwardSubjectPayment(subjectId, price, duration, phone,  response.accessToken, (res) => {
                console.log('values: ', res)
                //console.log("User Subjects: ", res);
                //const pastpaper = JSON.parse(localStorage.getItem('pastpaper'));
                //Grap title of the past paper later
                setUssd(res.ussd)
                setAction(res.action)
                setLoading(false);
                setIsWaitingPaymentConfirmation(true);


                const timerId = setInterval(() => {
                    //Keep calling backend to check the state of the transaction
                    GetSuccessfulPaymentsFromBackend(res.transaction_ref, response.accessToken, (resp) => {
                         
                        //Transaction if finally confirm
                        if(resp.transaction_status != "PENDING"){
                            setAction(resp.transaction_status);
                            setTransactionReason(resp.transaction_reason);
                            setTransactionMsg(resp.transaction_message);
                            
                            
                            //console.log('timerId reset: ', timerId)
                            
                            setIsPaymentDone(true)

                            if(resp.transaction_status == "SUCCESS"){
                                //call
                                //setIsTransactionSuccessful(true)
                                //console.log("about to update subject on backend: ", response)
                                GetPaperOnePastPapers(subjectId, response.accessToken, (respon) => {
                                    //console.log("response ", respon)
                                    if(respon.length > 0){
                                        setPapers([]);
                                        setPapers(resp);
                                        setPaymentReceivedSuccess(true);
                                        setStopTimer(timerId);
                                    }
                                    
                                })


                            }else{
                                //payment failed 
                                //setIsTransactionSuccessful(false);
                                //console.log("from Failed transaction: ", resp)
                            }

                            //clearInterval(timerId)
                        }

                        //console.log("inside check(): ", resp)

                        
    
                    })
                   }, 6000);
                
            } )

            

               
        })
        
    }

    return(
        <div>

            
            <Modal show={modalShow} onHide={handleClose} size='lg'>
            {paymentReceivedSuccessfully? <>
                <ValidateUserPayment title={title} duration={durationS}  price={priceS} closeModalFxn={handleClose}/>
            </>: <>
            
                <div  style={{minHeight: "400px"}}>
                {/** <ValidateUserPayment title={title} duration={durationS}  price={priceS} closeModalFxn={handleClose}/> */}
                {isWaitingPaymentConfirmation? <div> 
                    <div style={{display: "flex", justifyContent: "center", padding: "10px"}}>
                        {/*<Spinner animation="border" variant="primary" />*/}
                    </div>
                    <div>
                        
                        <div style={{textAlign: "center", padding: "6px"}}>
                            Payment has been submitted successfully!<br/><br/>
                            Payment Status: <b> {action === "SUCCESS" ? <> <span style={{color: "green"}}>{action}</span> 
                            </> : <>
                            {action === "PENDING" ? <>
                            
                            <span style={{color: "dodgerblue"}}>{action}</span> 
                            </> : <>
                            
                            <span style={{color: "red"}}>{action}</span> 
                            </> }
                            </>}  ...</b> {isPaymentDone? <> </>: <><Spinner animation="grow" variant="primary" /></>}
                        </div>
                        <div style={{textAlign: "center", padding: "10px"}}>
                            
                            {isPaymentDone? 
                            <>
                                {action === "SUCCESS" ? <div style={{color: "green"}}>
                                    <h6>{transaction_msg}</h6>
                                    <p>{transaction_reason}</p>
                                    {/*
                                    <ValidateUserPayment />
                                     */}
                                </div> : <div style={{color: "red"}}> 
                                    <h6>{transaction_msg}</h6>
                                    <br/>For<br/>
                                    <p>{transaction_reason}</p>
                                </div>}

                                
                            </> :
                             <>
                                <i>If no popup appears, please dial <b style={{color: "green"}}> {ussd} </b> and follow the instructions!</i>
                            </>}
                            

                            <p>

                            </p>
                        </div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Button variant='danger' onClick={handleClose}>CLOSE</Button>
                        </div>
                    </div>
                    <br/>
                    <div style={{textAlign: "center", fontSize: "0.7em", paddingBottom: "20px"}}>In Collaboration with <b>DIGITAL HOUSE INTERNATIONAL Online Payment Gateway</b> <br/><br/>
                    For any Enquiries: <br/>
                    Orange: +237 693 859 492 (sms, call, whatsapp)<br/>
                    MTN: +237 670 465 891 (sms, call, whatsapp)
                    </div>
                </div> : <>

                {isNext? <> 
                    <div className='next-pay-header'>
                        <div style={{fontSize: "2.8em"}}>
                        {salesIcon}
                        </div>
                        <div>
                            SUBJECT: <b>{localStorage.getItem('subjectTitle')}</b><br/>
                            AMOUNT: <b><b>{selectedPayment==='option1'? <>{oneMonth}</> : <>
                        {selectedPayment==='option2'? <>{threeMonths}</> : <>{tenMonths}</>}
                        </>}</b>  Fcfa</b><br/>
                            DURATION: <b> {selectedPayment==='option1'? <> 01 Month (30D)</> : <>
                        {selectedPayment==='option2'? <> 03 Months (90D)</> : <> 10 Months (300D)</>}
                        </>}</b>
                        </div>
                    </div>
                </>: <>
                <Modal.Header closeButton style={{backgroundColor: "#4682b4", color: "white", padding: "6px"}}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                </>}
                

                <Modal.Body>
                    {isNext? <>
                        <h6>SELECT PAYMENT METHOD</h6>
                       
                    <div className='paymethod-container'>
                        <div className="radio">
                            <label>
                                <input type="radio" value="option4" checked={nextSelectedPay==='option4'} onChange={handleFinalOptionChange} />
                                <span className={nextSelectedPay==='option4'? "payment-description" : ""}>
                                <b>MTN Mobile Money</b> 
                                </span>
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input type="radio" value="option5" checked={nextSelectedPay==='option5'}  onChange={handleFinalOptionChange} />
                                <span className={nextSelectedPay==='option5'? "payment-description" : ""}>
                                <b>Orange Money</b> 
                                </span>
                                
                            </label>
                        </div>
                    </div>

                    

                    <div style={{fontSize: "0.7em", padding: "10px 0 0 10px"}}>
                        
                        (1.) Enter Valid MoMo Phone Number<br/>
                        (2.) Hit <b>COMPLETE PAYMENT</b><br/>
                        {isOMSelect? <>
                            (3.) Dial <b style={{color: "green"}}>#150*50#</b> Code to initiate Payment<br/>
                        </>: <>
                        (3.) Dial <b style={{color: "green"}}>*126# </b> Code to initiate Payment<br/>
                        </>}  
                        (4.) Enter <b>PIN</b> to validate Payment
                        
                    </div>
                    <hr/>

                    <div style={{marginTop: "2em"}}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">+237</InputGroup.Text>
                                <Form.Control
                                    placeholder="e.g. 611222333"
                                    aria-label="Username"
                                    value={phone}
                                    name='phone'
                                    type='number'
                                    onChange={handlePhoneChange}
                                    aria-describedby="basic-addon1"
                                    fontSize="10px"
                                />
                            </InputGroup>
                    </div>

                    
                    </>:<>
                    <div className="radio">
                        <label>
                            <input type="radio" value="option1" checked={selectedPayment==='option1'} onChange={handleOptionChange} />
                            <span className={selectedPayment==='option1'? "payment-description" : ""}>
                            01 Month (30D): <b>{oneMonth}  Fcfa</b> <span style={{fontSize: "0.6em",paddingLeft: "6px"}}><i style={{textDecoration: "line-through"}}> {oneMonth*3} </i> Fcfa</span>
                            </span>
                        </label>
                    </div>
                    <div className="radio">
                        <label>
                            <input type="radio" value="option2" checked={selectedPayment==='option2'}  onChange={handleOptionChange} />
                            <span className={selectedPayment==='option2'? "payment-description" : ""}>
                                03 Months (90D): <b>{threeMonths} Fcfa</b> <span style={{fontSize: "0.6em",paddingLeft: "6px"}}><i style={{textDecoration: "line-through"}}> {threeMonths*3} </i> Fcfa</span>
                            </span>
                            
                        </label>
                    </div>
                    <div className="radio">
                        <label>
                            <input type="radio" value="option3" checked={selectedPayment==='option3'}  onChange={handleOptionChange}  />
                            <span className={selectedPayment==='option3'? "payment-description" : ""}>
                                10 Month (300D): <b> {tenMonths} Fcfa</b> <span style={{fontSize: "0.6em",paddingLeft: "6px"}}><i style={{textDecoration: "line-through"}}>{tenMonths*3}</i> Fcfa</span>
                            </span>
                            
                        </label>
                    </div>
                    <br/>
                    <div className='selected-pay'>
                        Total: <b>{selectedPayment==='option1'? <>{oneMonth}</> : <>
                        {selectedPayment==='option2'? <>{threeMonths}</> : <>{tenMonths}</>}
                        </>}</b> Fcfa
                    </div>
                    </>}
                    
                </Modal.Body>
                {isNext? <>
                    {isError? <>
                        <div style={{textAlign: "center", marginTop: "-10px", paddingBottom: "18px", color: "red"}}><i>Phone number should be 9 digits long</i></div>
                    </> : <></> }{
                        
                    }
                    <div style={{width: "50%", margin: "auto", paddingBottom: "12px"}}>
                    {loading? <>
                            <Spinner animation="border" variant="primary" />
                        </>: <>
                        
                        </>}
                    </div>
                    <div className='isNextFooter'>
                        
                    <Button variant="secondary" onClick={handleBackToPrev} style={{background: "lightGray", padding: "0.08em", border: "0px", color: "black"}}> 
                        &lt; Back
                    </Button>
                    <Button variant="danger" onClick={handleSubmitPayment} disabled={isCorrect}>
                        COMPLETE PAYMENT
                    </Button>
                    </div>
                </>: <>
                
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleMoveNext}>
                        Next &gt;
                    </Button>
                </Modal.Footer>
                </>}
                
                </> }
                

                </div>
            
            </>}

                
                
            </Modal>


            {papers && papers.length > 0 ? <>

                {papers.map((paper) => {
                    return(
                        <div key={paper.id} style={mystyle}>
                            <div onClick={(() => handleLoadMcq(paper))} style={removeBtnStyle}>
                                <div>
                                    <div style={{float: "left", border: "2px solid white", padding: "7px",margin: "10px", borderRadius: "1.6em 1%",     color: "white", width: "17%"}}>
                                        <b>MCQ</b><br/>
                                        <span style={{fontSize: "0.5em"}}>{paper.month} {paper.id.slice(-4)}</span>
                                    </div>
                                    <p style={{color: "black", fontSize: "0.9em", float: "left", wordWrap: "break-word", width: "63%"}}>
                                        <b>{paper.title}</b><br/> 
                                        <span style={{fontSize: "0.7em"}}>{paper.quantity} MCQs</span><br/>
                                        <span style={{fontSize: "0.7em"}}>
                                        {paper.isGreen? <>
                                            <span style={{color: "green"}}>Cumulative Score: <b>{paper.correctAnsweredCount}</b></span>
                                            </>: <>
                                            {paper.isRed? <>
                                                <span style={{color: "white"}}>Cumulative Score: <b>{paper.correctAnsweredCount}</b></span>
                                            </>: <>
                                            {paper.isYellow? <>
                                                <span style={{color: "yellow"}}>Cumulative Score: <b>{paper.correctAnsweredCount}</b></span>
                                            </>: <>
                                            
                                            </>}
                                            </>}
                                        </>}
                                        </span>
                                        
                                    </p>
                                    {paper.isLoading? <>
                                        <div className="spinner-border text-warning" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </>: <></>}
                                    <span style={{float: "right", backgroundColor: paper.statusColor, color: "white", padding: "2px", borderRadius: "0.5em", fontSize: "0.5em", fontWeight: "bold", minWidth: "42px", textAlign: "center" }}>{paper.status}</span>
                                    <br style={{clear: "both"}} />
                                </div>
                            </div>
                            
                            
                        </div>
                    )
                })}

            </> : <div style={{width: "50%", margin: "auto"}}>
            <Spinner animation="border" variant="primary" />
            
            
            </div>}
            

            
        </div>
    );
}


export default PaperOnepastpapersPage;