import React, { useEffect, useState } from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleCheck, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment'



function AllOlympiad(props){
    
    let checkIcon = <FontAwesomeIcon style={{color: "#22A370", fontSize: "2.21em" }} icon={faCircleCheck}  /> 
    let arrowIcon = <FontAwesomeIcon style={{color: "gray", fontSize: "1.51em" }} icon={faChevronRight}  /> 

    let {olympiads} = props;

    const toUpperCaseFilter = (d) => {
        return d.toUpperCase();
    };

   

    return (
        <div>
            {olympiads && olympiads.length > 0? <>
                    {olympiads.map((olympiad) => {
                        return(
                            <div key={olympiad.id} style={{cursor: "pointer"}} onClick={() => props.handleLoadOlympiadMcqs(olympiad)}>
                                <div style={{display: "flex", border: "1px solid #DCE3EC", height: "80px", backgroundColor: "white", borderRadius: "0.5em", margin: "0.3em" }}>
                                
                                    <div style={{width: "18%", display: "flex", alignItems: "center" }}>
                                        <div style={{margin: "auto", width: "50%"}}>
                                            <span style={{borderRadius: "1.4em", display: "inline-block"}}>{checkIcon}</span>
                                        </div>
                                    </div>

                                    <div style={{width: "72", fontSize: "0.8em", display: "flex", alignItems: "center"}}>
                                        
                                        <div style={{padding: "10px", fontSize: "0.9em"}}>
                                            <b>{olympiad.olympiadTitle}</b> <br/> 
                                            <span>On: <Moment  filter={toUpperCaseFilter}>{olympiad.writtenDate}</Moment><br/>
                                            Duration: {olympiad.durationInMinutes}20mins, {olympiad.score}/{olympiad.questionCount} MCQs, {olympiad.participantsCount} participants   </span> 
                                        </div>              

                                    </div>

                                    <div style={{display: "flex", width: "10%",  alignItems: "center"}}>
                                        <div style={{margin: "auto", width: "50%"}}>
                                            {arrowIcon}
                                    </div>
                                </div>
                            </div>
                        </div>
                        )

                    })}
                    

             </> : <></>}
        </div>
    )
}


export default AllOlympiad;