
import React, {useState, useEffect} from 'react'
import { useParams } from "react-router-dom";
import {Container, Alert, Button, Row, Col, Spinner, Modal} from "react-bootstrap";
import Pagination from 'react-bootstrap/Pagination';
import {getQuizUsingCode, sendQuizSolutionsToBackEnd, sendUserQuizToBackEnd} from '../MYAPI';
import { Pie } from 'react-chartjs-2';
import {Chart, ArcElement, Tooltip, Legend} from 'chart.js'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { GetMcqsForGivenPastPaper, ForwardUserMCQReport, ForwardUserQuizScore, GetMcqsForGivenTopicNumber } from "../MYAPI";
import McqCard from './McqCard';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDatabase, faL} from '@fortawesome/free-solid-svg-icons'


function McqQuestons(){
    let dbIcon = <FontAwesomeIcon style={{color: "dodgerblue", fontSize: "1.1em" }} icon={faDatabase}  />
    const [loading, setLoading] = useState(false)
    const [mcqs, setMcqs] = useState([])
    const [activePage, setActivePage] = useState(1);
    const [mcqsPerPage, setMcqsPerPage] = useState(10);
    const [score, setScore] = useState(0);
    const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
    const [tooltipOpen, settooltipOpen] = useState(false);
    const [loadingReport, setloadingReport] = useState(true);
    const [pastpaperTitle, setPastPaperTitle] = useState("")
    const [questionNumber, setquestionNumber] = useState('');
    const [tick, setTick] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const [currentMcqs, setCurrentMcqs] = useState([]);
    const [unansweredCount, setUnansweredCount] = useState(0);
    const [wrongCount, setWrongCount] = useState(0);
    const [report, setReport] = useState('');
    const [title, setTitle] = useState("");
    const [m, setM] = useState(null);
    const [showtxtArea, setShowTxtArea] = useState(false);
    const [message, setMessage] = useState('');
    const [showReportFeedbackSubmit, setShowReportFeedbackSubmit] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [dataPie, setDataPie] = useState(
        {   labels: ["Red", "Green", "Orange"],
            label: "Score Chart",
            datasets: [
            {
                data: [score, 0, 0],
                backgroundColor: ["#F7464A", "#46BFBD", "#FDB45C"  ],
                hoverBackgroundColor: ["#FF5A5E", "#5AD3D1", "#FFC870"]
            } ]
        });


    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const [isfromVideo, setIsfromVideo] = useState(false);
    const [isFromReport, setIsFromReport] = useState(false);
    const [isFromSubmittedSolution, setIsFromSubmittedSolution] = useState(false)

    const [rank, setRank] = useState('---');


    let {pastpaperId, paperTitle, topicId, topicTitle} = useParams();
    
    useEffect(() => {
        setLoading(true);
        setTitle(paperTitle);
        const request = {
            ...loginRequest, 
            account: accounts[0]
        };
        
        instance.acquireTokenSilent(request).then((response) => {
            if(topicId && topicId > 0){
                console.log("Questions by Topic: ", 'UserId: ', response.idTokenClaims.sub);
                GetMcqsForGivenTopicNumber(topicId, response.accessToken, response.idTokenClaims.sub, (res) => {
                    setMcqs(res);
                    setRank("");
                    setLoading(false);
                    setTitle(title)
                })
            }else{
                GetMcqsForGivenPastPaper(pastpaperId, response.accessToken, response.idTokenClaims.sub, (res) => {
                    setMcqs(res);
                    setRank("");
                    //console.log("User Subjects: ", res);
                    //const pastpaper = JSON.parse(localStorage.getItem('pastpaper'));
                    //Grap title of the past paper later
                    setLoading(false);
                } )
            }
            
        })

    }, [])

    const handleFocus = () => {
        setShowTxtArea(true)
    }

    const handleTxtAreaChange = (event) => {
        //event.preventDefault()
        setMessage(event.target.value);
        //console.log("State Value", message);
        setShowWarning(false);
    }

    function handleReportSubmitToBackend(event){
        event.preventDefault();

        if(message && message.length < 50){       
                
            setShowWarning(true)
        }else{
            //Callbackend to send the report

            //setShowWarning(false);
            setShowReportFeedbackSubmit(true);
            //setShowTxtArea(false);
            setMessage('');

            setTitle(paperTitle);
            //setShow(false);

            const request = {
                ...loginRequest, 
                account: accounts[0]
            };
            
            instance.acquireTokenSilent(request).then((response) => {
                //console.log("Access Token: ", response.accessToken);
                ForwardUserMCQReport(m.id, m.pastPaperId, m.pastPaperId.slice(0,3), message, m.position, response.accessToken, (res) => {
                    //setMcqs(res);
                    //console.log("Return value  of Forwarded report: ", res);
                    //const pastpaper = JSON.parse(localStorage.getItem('pastpaper'));
                    //Grap title of the past paper later
                    setLoading(false);
                } )
            })
            
        }
        
    }

    ///
    const handleChange = (m, option) => {
        /*
        console.log("Selected MCQ: ", m);
        console.log("Option Selected: ", option);
        console.log("MCQ Position: ", m.position);*/
        changeOptionSelectedValueFxn(option);
        setTick(!tick);
    }

    const handlePageChange = (pageNumber) => {
        console.clear();
        //console.log("Page Number: ", pageNumber);
        setCurrentMcqs(mcqs.slice(indexOfFirstMcq, indexOfLastMcq))
        setActivePage(pageNumber);
    }

    const handleImgOptionChange = (m, option) => {
        changeOptionSelectedValueFxn(option);
        setTick(!tick);
    }

    const handlegetCorrectionAnwerList = (m) => {
        let text = "";
        if(m.answer > 0){
            switch(m.answer) {
                case 1:
                    text = "A";
                  break;
                case 2:
                    text = "B";
                  break;                          
                case 3:
                    text = "C";
                break;
                case 4:
                    text = "D";
                break;
                case 5:
                    text = "E";
                    break;
                default:
                  // code block
              } 
        }else{
            if(m.mcqOptions.length > 0){
                var options = m.mcqOptions;
                for(let i = 0; i<m.answers.length; i++){
                    var index = m.answers[i];
                    if(i>0 && i < m.answers.length + 2){
                        text += ", ";
                    }
                    
                    switch(index) {
                        case 1:
                            text += "A";
                          break;
                        case 2:
                            text += "B";
                          break;                          
                        case 3:
                            text += "C";
                        break;
                        case 4:
                            text += "D";
                        break;
                        case 5:
                            text += "E";
                            break;
                        default:
                          // code block
                    }
                    
                }
            }else{
                var options = m.mcqOptionImages;
                for(let i = 0; i<m.answers.length; i++){
                    var index = m.answers[i];
                    if(i>0 && i < m.answers.length + 2){
                        text += ", ";
                    }

                    switch(index) {
                        case 1:
                            text += "A";
                          break;
                        case 2:
                            text += "B";
                          break;                          
                        case 3:
                            text += "C";
                        break;
                        case 4:
                            text += "D";
                        break;
                        case 5:
                            text += "E";
                            break;
                        default:
                          // code block
                      }

                    
                }
            }
        }
        if(m.answer === 0){
            text += "."
        }
        return text;
    }

    const handleSendReport = (mcq) => {
        setIsfromVideo(false);
        setIsFromSubmittedSolution(false);
        setIsFromReport(true);

        setShowReportFeedbackSubmit(false);
        //console.clear();
        //console.log(m);
        setM(mcq);
        var p = mcq.position;
        var msg = "Reporting Question " + p;
       // console.log("Position: ", p)
        setTitle(msg)
        setShow(true);
        
        
    }
    const showExplanationFn = (m) => {
        m.showExplanation = !m.showExplanation;
        setShowExplanation(!showExplanation);
        
    }

    function changeOptionSelectedValueFxn(option){
        if(option.optionSelectedValue === null){
            option.optionSelectedValue = true;
        }else {
            option.optionSelectedValue = !option.optionSelectedValue;
        }
        
    }

    const handleSubmitAnswers = () => {
        setLoading(true);
        if(!isAnswerSubmitted){
            //Marking
        for(let i = 0; i<mcqs.length; i++){
            mcqs[i].isAnswerSubmitted = true;

            if(mcqs[i].answer > 0){
                //1. if more than one option is ticked, the answer is wrong
                //2. if m.answer - 1 === index of option selected option
                var options = mcqs[i].mcqOptions;

                if(options && options.length > 0){
                    var numberOfSelectedOptions = mcqs[i].mcqOptions.filter(x => x.optionSelectedValue === true);
                    
                    if(numberOfSelectedOptions.length > 1){
                        //If more than one option was selected, then wrong answer since ONLY one option is CORRECT.
                        mcqs[i].answerStatus = "wrong";
                        mcqs[i].color = "red";
                    }else if(numberOfSelectedOptions.length === 1){
                        //if ONLY ONE OPTION
                        var selectedPosition = mcqs[i].answer - 1;
                        if(mcqs[i].mcqOptions[selectedPosition].optionSelectedValue){
                            mcqs[i].answerStatus = "correct";
                            mcqs[i].color = "green";
                        }else{
                            mcqs[i].answerStatus = "wrong";
                            mcqs[i].color = "red";
                        }
                        
                    }else{
                        mcqs[i].answerStatus = "unanswered";
                        mcqs[i].color = "yellow";
                    }
                }else{ //Dealing with image options
                    var numberOfSelectedOptions = mcqs[i].mcqOptionImages.filter(x => x.optionSelectedValue === true);
                    
                    if(numberOfSelectedOptions.length > 1){
                        //If more than one option was selected, then wrong answer since ONLY one option is CORRECT.
                        mcqs[i].answerStatus = "wrong";
                        mcqs[i].color = "red";
                    }else if(numberOfSelectedOptions.length === 1){
                        //if ONLY ONE OPTION
                        var selectedPosition = mcqs[i].answer - 1;
                        if(mcqs[i].mcqOptionImages[selectedPosition].optionSelectedValue){
                            mcqs[i].answerStatus = "correct";
                            mcqs[i].color = "green";
                        }else{
                            mcqs[i].answerStatus = "wrong";
                            mcqs[i].color = "red";
                        }
                        
                    }else{
                        mcqs[i].answerStatus = "unanswered";
                        mcqs[i].color = "yellow";
                    }
                }

            }

            if(mcqs[i].answer === 0){
                
                var isCorrect = true; //Assume the answer is true
                mcqs[i].answerStatus = "correct";
                
                //If the question contains options
                if(mcqs[i].mcqOptions && mcqs[i].mcqOptions.length > 0){
                    var options = mcqs[i].mcqOptions.filter(x => x.optionSelectedValue === true);
                    var ans = mcqs[i].answers;
                    
                    //If number of selected options = number of responses privided
                    if(ans.length === options.length) {
                        //Count same ===> possibility of matching selected options
                        for(let j = 0; j<ans.length; j++){
                            var optionPosition = ans[j] - 1;

                            if(mcqs[i].mcqOptions[optionPosition].optionSelectedValue === false){
                                isCorrect = false;
                                mcqs[i].answerStatus = "wrong";
                                mcqs[i].color = "red";
                            }
                        }
                    }else{
                        isCorrect = false;
                        mcqs[i].answerStatus = "wrong";
                        mcqs[i].color = "red";
                    }
                    if(options.length === 0){
                        mcqs[i].answerStatus = "unanswered";
                        mcqs[i].color = "yellow";
                    }
                    //Question contains image options
                }else{
                    if(mcqs[i].mcqOptionImages && mcqs[i].mcqOptionImages.length > 0){
                        var options = mcqs[i].mcqOptionImages.filter(x => x.optionSelectedValue === true);;
                        ans = mcqs[i].answers;

                        if(mcqs[i].answers.length === options.length) {
                            //Count same ===> possibility of matching selected options
                            for(let j = 0; j<ans.length; j++){
                                var optionPosition = ans[j] - 1;
                                if(mcqs[i].mcqOptionImages[optionPosition].optionSelectedValue == false){
                                    isCorrect = false;
                                    mcqs[i].answerStatus = "wrong";
                                    mcqs[i].color = "red";
                                }
                            }
                        }else{
                            isCorrect = false;
                            mcqs[i].answerStatus = "wrong";
                            mcqs[i].color = "red";
                        }

                        if(options.length === 0){
                            mcqs[i].answerStatus = "unanswered";
                            mcqs[i].color = "yellow";
                        }
                    }

                }

            }
            
            mcqs[i].showExplanation = false;
        }
        setRank('');
        //Submit answers to backend
        //Loading... the ux

        var subjectId = 0;
        var pastpaperId = "";
        var pastpaperNumber = 1;
        var topicNumber = 0;
        var questionPosition = -1;
        var paperYear =  "";
        var answerStatus = 3;
        var pastpapersId = "";
        var answersCollection = "";
        
            var zero = 0;
            var one = 1;
            var two = 2;
            var isSet = true;
            var count = 1;

            //constructing submitted answers before forwarding to backend
        for(let i = 0; i<mcqs.length; i++){
            
            subjectId = parseInt( mcqs[i].pastPaperId.slice(0,3) );
            pastpaperId = mcqs[i].pastPaperId;
            
            
            questionPosition = mcqs[i].position;
            paperYear =  "";
            if(mcqs[i].answerStatus === "correct"){
                answerStatus =  2
            }else if(mcqs[i].answerStatus === "wrong"){
                answerStatus = 1;
            }else{
                answerStatus = -1;
            }
                        
            if(count < mcqs.length){
                if(mcqs[i].answerStatus === "correct"){
                    answersCollection = answersCollection + mcqs[i].position.toString() + "-" + one.toString() + "-" + mcqs[i].topicId.toString() + ",";
                }else if(mcqs[i].answerStatus === "wrong"){
                    answersCollection = answersCollection + mcqs[i].position.toString() + "-" + two.toString() + "-" + mcqs[i].topicId.toString() + ",";
                }else{
                    answersCollection = answersCollection + mcqs[i].position.toString() + "-" + zero.toString() + "-" + mcqs[i].topicId.toString() + ",";
                }

                if (isSet)
                    {
                        pastpaperId = mcqs[i].PastPaperId;
                        isSet = false;
                    }
            }else{
                if(mcqs[i].answerStatus === "correct"){
                    answersCollection = answersCollection + mcqs[i].position.toString() + "-" + one.toString() + "-" + mcqs[i].topicId.toString();
                }else if(mcqs[i].answerStatus === "wrong"){
                    answersCollection = answersCollection + mcqs[i].position.toString() + "-" + two.toString() + "-" + mcqs[i].topicId.toString();
                }else{
                    answersCollection = answersCollection + mcqs[i].position.toString() + "-" + zero.toString() + "-" + mcqs[i].topicId.toString();
                }

                if (isSet)
                    {
                        pastpaperId = mcqs[i].PastPaperId;
                        isSet = false;
                    }
            }
            count = count + 1;
        }

        //console.log("Submitted before Error: ", subjectId, pastpaperId, pastpaperNumber, topicNumber, questionPosition, paperYear, answerStatus);
        

        if(pastpapersId.length < 1){
            pastpapersId = pastpaperId;
        }
                    
        const right = mcqs.filter(x => x.answerStatus === "correct").length;
        const wrong = mcqs.filter(x => x.answerStatus === "wrong").length;

        setScore(mcqs.filter(x => x.answerStatus === "correct").length);
                   
        
        setWrongCount(mcqs.filter(x => x.answerStatus === "wrong").length);
        setUnansweredCount(mcqs.filter(x => x.answerStatus === "unanswered").length);

        const request = {
            ...loginRequest, 
            account: accounts[0]
        };
      
        const studentScore = right;
        
        instance.acquireTokenSilent(request).then((response) => {
            //console.log("Access Token: ", response.accessToken);
            ForwardUserQuizScore(pastpaperId, studentScore, answersCollection, response.accessToken,
                (res) => {
                    console.log("res", res);
                    const myrank = res.rank.toString() + " out of " + res.total;
                    setRank(myrank);
                },
                (err) => {
                    console.log("Error: ",err);
                } 
            );
        })

        //setPieChartData(mcqs.filter(x => x.answerStatus === "correct").length, mcqs.filter(x => x.answerStatus === "wrong").length, mcqs.filter(x => x.answerStatus === "unanswered").length);
        const data = {
            labels: ['Correct', 'Wrong', 'Skipped'],
            datasets: [
                {
                label: '# of Score',
                data: [mcqs.filter(x => x.answerStatus === "correct").length, mcqs.filter(x => x.answerStatus === "wrong").length, mcqs.filter(x => x.answerStatus === "unanswered").length],
                backgroundColor: [
                    "Green",
                    "Red",
                    "Orange"
                ],
                borderColor: [
                    'White',
                    'White',
                    'White'
                ],
                borderWidth: 1,
                },
            ],
            };          
         
        setDataPie(data);       
        setIsAnswerSubmitted(true);
        //localStorage.removeItem('quiz');

        setShow(true);
        
        setIsFromReport(false);
        setIsfromVideo(false);
        setIsFromSubmittedSolution(true);

        }

        setLoading(false);       
        
    }

    const setPieChartData = (c, w, u) => {
        //var score = mcqs.filter(x => x.answerStatus === "correct").length;
        

        const dataValue = [score, wrongCount, unansweredCount];
        console.log("DataValue; ",dataValue);

        const data = {
            labels: ['Correct', 'Wrong', 'Skipped'],
            datasets: [
                {
                label: '# of Score',
                data: [c, w, u],
                backgroundColor: [
                    "Green",
                    "Red",
                    "Orange"
                ],
                borderColor: [
                    'White',
                    'White',
                    'White'
                ],
                borderWidth: 1,
                },
            ],
            };          
            
        setDataPie(data);

    }
    
    const  showvideoBtnClick = (mcq) => {
        setIsFromReport(false);
        setIsFromSubmittedSolution(false);
        setIsfromVideo(true);

        setM(mcq);
        setTitle("Question " + mcq.position + " explanation")
        setIsfromVideo(true);
        setShow(true);
        
        
    }

    const pageNumbers = [];
    
    for (let number = 1; number <= Math.ceil(mcqs.length / mcqsPerPage); number++) {
        pageNumbers.push(
            <Pagination.Item key={number} page={activePage} active={number === activePage}
            onClick={() => handlePageChange(number)}
            >
            {number}
            </Pagination.Item>,
        );
        }
    const handleCloseModal = () =>{
        setTitle(paperTitle);
        setShow(false);
    } 
    const handleShowModal = () => setShow(true);

    const indexOfLastMcq = activePage * mcqsPerPage;
    const indexOfFirstMcq = indexOfLastMcq - mcqsPerPage;
    const currentMcqsList = mcqs.slice(indexOfFirstMcq, indexOfLastMcq);

    return(
        <div style={{maxWidth: "45rem", marginInline: "auto"}}>
            {loading? <>  
                <div style={{display: "flex", justifyContent: "center",alignContent: "center", justifyItems: "center"}}>
                    <Spinner animation='border' variant='primary' />
                </div>
            </> : <></>}
            <div>
                {mcqs.length > 0 ? <>
                    <Alert variant='info'>
                    <p>{title}</p>
                        <p>{isAnswerSubmitted? <> Score: <b>{score} / {mcqs.length}</b> </>: null}</p>
                    </Alert>
                </> : <></>}
                
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {loading? <>
                    <Spinner animation="border" variant="primary" />
                </> : <>
                <Pagination> {pageNumbers} </Pagination>
                </>}
            </div>

            {
                currentMcqsList.map((mcq) => {
                    return(
                        <div key={mcq.id}>
                                                       
                            <McqCard mcq={mcq} key={mcq.id}  updateMcqList={handleChange} updateMcqImgList={handleImgOptionChange} getCorrectionAnwerList={handlegetCorrectionAnwerList}
                            sendReportFn={handleSendReport} showvideoBtnClickFn={showvideoBtnClick}
                            showExplanationFxn={showExplanationFn}/>
                        </div>
                    )
                    
                })
            }

                <Modal
                    show={show}
                    onHide={handleCloseModal}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{width: "100%", padding:"2px", border:'0px solid gray'}}>

                            {isFromSubmittedSolution? <>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: "50%", backgroundColor: "whitesmoke", height: "30px", width: "30px", margin: "auto", color: "whiteSmoke", backgroundColor:"brown", position: "relative", top:"-35px", border: "1px solid yellow"}}>
                                <h1 style={{fontSize: "0.8em"}}><b> {accounts[0] && accounts[0].name.slice(0,1).toUpperCase()} </b></h1>
                                </div>
                                <h5 style={{textAlign: "center", position: "relative", top:"-35px", fontSize: "0.7em"}}> {accounts[0] && accounts[0].name} </h5>
                                <hr style={{position: "relative", top: "-35px"}}/>

                                <div style={{position: "relative", top: "-35px"}}>                                   
                                    <div style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                        <div style={{width: "120px", border: "1px solid gray", padding: "4px", marginRight: "20px", borderRadius: '10px', textAlign: 'center', backgroundColor: "#2F4F4F", color: "whitesmoke", fontSize: "0.7em"}}>
                                            SCORE<br/>
                                            <b>{score}</b><br/>
                                            Out of {mcqs.length}
                                        </div>
                                        <div style={{width: "120px", border: "1px solid gray", padding: "4px", borderRadius: '10px', textAlign: 'center', backgroundColor: "#663399", color:"whitesmoke", fontSize: "0.7em"}}>
                                            RANK<br/>
                                            <b>---</b><br/>
                                            {rank && rank.length > 1? <>
                                            {rank}
                                            </> : <>
                                            <div className="spinner-border text-warning" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            </>}
                                        </div>
                                    </div>
                                    <div>
                                        <hr />
                                            <p style={{textAlign: "center", fontSize: "0.8em"}}>
                                                <b>Question Analysis</b>
                                            </p>                                        
                                        <hr />
                                        {mcqs.map(m => {
                                            return (
                                                <span key={m.id} >
                                                    {/*Correct*/}
                                                    {m.answerStatus === "correct" ? <>
                                                    {m.position < 10? <>
                                                    <span style={{backgroundColor:"green", borderRadius: "50%", width:"40px", height: "40px", color:"white", padding: '5px', margin: "4px", display: "inline-block", textAlign: "center"}}>{m.position} </span>
                                                    </>: <>
                                                        <span style={{backgroundColor: "green", borderRadius: "50%", width:"40px", height: "40px", color:"white", padding: "4px", margin: '4px', display:"inline-block", textAlign: "center"}}>{m.position}</span>
                                                    </>}
                                                    </> : <>
                                                    {/*Wrong*/}
                                                    {m.answerStatus === "wrong" ? <>
                                                    {m.position < 10? <>
                                                    <span style={{backgroundColor:"red", borderRadius: "50%", width:"40px", height: "40px", color:"white", padding: '5px', margin: "4px", display: "inline-block", textAlign: "center"}}>{m.position} </span>
                                                    </>: <>
                                                        <span style={{backgroundColor: "red", borderRadius: "50%", width:"40px", height: "40px", color:"white", padding: "4px", margin: '4px', display:"inline-block", textAlign: "center"}}>{m.position}</span>
                                                    </>}
                                                    </> : <>
                                                    {/*UnAttempted*/}
                                                    {m.position < 10? <>
                                                    <span style={{backgroundColor:"orange", borderRadius: "50%", width:"40px", height: "40px", color:"white", padding: '5px', margin: "4px", display: "inline-block", textAlign: "center"}}>{m.position} </span>
                                                    </>: <>
                                                        <span style={{backgroundColor: "orange", borderRadius: "50%", width:"40px", height: "40px", color:"white", padding: "4px", margin: '4px', display:"inline-block", textAlign: "center"}}>{m.position}</span>
                                                    </>}
                                                    
                                                    </>}
                                                    
                                                    </>}
                                                    
                                                </span>
                                            )
                                        })}
                                        
                                    </div>
                                    <div>
                                        <hr />
                                        <span style={{color: "green"}}>&#10003; &nbsp; &nbsp; Correct:  &nbsp;  <b>{score}</b></span> <br/>
                                        
                                        <span style={{color: "red"}}>&#10060;  &nbsp; &nbsp; Incorrect:  &nbsp; <b>{wrongCount}</b></span> <br/> 
                                        
                                        <span style={{color: "orange"}}> <b style={{fontSize: "1.2em"}}>&gt;</b> &nbsp;  &nbsp; Skipped: &nbsp; <b>{unansweredCount}</b></span> <br/>
                                                                        
                                        <hr />
                                    </div>
                                    <div style={{ margin: "auto"}}>
                                    {dataPie? <Pie data={dataPie} options={{ responsive: true }} redraw={true}/> : null}
                                    </div>
                                </div>
                            
                             </>: <></>}

                            {isfromVideo? <>
                                {m.videoUrl && m.videoUrl.length > 0 ? <>
                                <video controls controlsList="nodownload"
                                src={m.videoUrl}                    
                                height="320"
                                width="100%"
                                autoPlay={false}
                                volume="1"
                                >
                                Sorry, your browser doesn't support embedded videos,
                                but don't worry, we recommend using Chrome browser or updating the current Chrome brower

                            </video>
                            </> : <></>} 
                            
                            </>: <></>}

                            {isFromReport? <>
                                
                                <div style={{ width: "100%", padding: "6px"}}>
                                    <form onSubmit={handleReportSubmitToBackend}>
                                        {showtxtArea? <> 
                                            <p style={{textAlign: "center"}} id="ptxtArea">
                                                Share your view about the solution to this question. Your opinion will be taken into consideration to make the content better.
                                            </p>
                                        
                                            {showReportFeedbackSubmit? 
                                            <>
                                                <div style={{border: "1px solid gray", padding: "8px", borderRadius: "0.5em", backgroundColor: "#EFF4FC "}} id="ptxtArea">
                                                    {dbIcon} <b>Your report was successfully submitted.</b> 
                                                    <hr />
                                                    <div style={{display: "flex", justifyContent: "cent", alignItems: "center"}}>
                                                        <span>To know the resolved state of this report, check on the subject's forum.<br/>
                                                        <b>Thanks for the feedback!!!</b></span>
                                                    </div>
                                                </div>

                                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px"}}>
                                                    <button style={{borderRadius: "0.5em", backgroundColor: "red", color: "white", border: "1px solid red", padding: "7px", width: "100px"}} onClick={handleCloseModal}>CLOSE</button>
                                                </div>
                                            </> : <>
                                        
                                        
                                        
                                        </>}
                                        
                                        </>: <></>}
                                        {showReportFeedbackSubmit? <></>: <>
                                        <div style={{ width: "100%", }}>
                                            <textarea type="textarea" required 
                                            id="message"
                                            name="message"
                                            value={message}
                                            rows={6}
                                            placeholder='Share your view about the solution to this question. Your opinion will be taken into consideration to make the content better.'
                                            onFocus={handleFocus}
                                            onChange={handleTxtAreaChange}
                                            style={{width: "100%", padding: "10px", backgroundColor: "#f8f8f8", border: "2px solid #ccc", borderRadius: "6px", fontSize: "12"}} />
                                        </div><br/>
                                        {showWarning? <>
                                            <p style={{color: "red", fontSize: "0.7em"}}>
                                                <i>A report should be atleast <b>50 letters long</b>. We need a detailed description of the suggestions we receive! <br/> Length Now: {message.length} characters </i> 
                                            </p>
                                        </> : <></>}
                                        
                                        <button style={{float: "right", borderRadius: "0.5em", backgroundColor: "dodgerblue", color: "white", border: "1px solid dodgerblue", padding: "7px"}} type="submit">Send Suggestion</button>
                                        </>}
                                        
                                    </form>
                                    <br style={{clear: "right"}} />
                                </div>

                            </>: <></>}

                        </div>
                    
                    </Modal.Body>
                    {isfromVideo && isFromReport && isFromSubmittedSolution? <>

                        <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    {/*<Button variant="primary">Understood</Button>*/}
                    </Modal.Footer>

                    </>: <></>}
                    
                </Modal>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {loading? <>
                    
                </> : <>
                <Pagination> {pageNumbers} </Pagination>
                </>}
            </div>
            
            <div style={{display: 'flex', alignItems: 'end', justifyContent: 'end', marginBottom: "80px"}}>
                {loading? <>
                    
                </> : <>
                {mcqs.length > 0 ? <>
                    <Button type='button' disabled={isAnswerSubmitted} className='primary' onClick={handleSubmitAnswers}>SUBMIT ANSWERS</Button>
                </> : <> </>}
                
                </>}
            </div>


            
        </div>
    )
}

export default McqQuestons;