import React, { useEffect, useState } from "react";
import './home.css'
import {GetHomePageInfo} from '../MYAPI'
import Moment from 'react-moment'

function HomePage(){
    const [homepageInfo, setHomepageInfo] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(true)
    const [id, setId] = useState('');
    const [daysToExam, setDaystoExam] = useState(0);

useEffect(() => {
    setId('...');
    if(homepageInfo === null){
        setId(".")
    }
})
    useEffect(() => {
        
        setLoading(true);
        GetHomePageInfo(true, (res) => {
            setHomepageInfo(res);
            //setLoading(false);
            //console.log("from home page res: ", res)
            const startdate = homepageInfo.startDate.split('T')
            const enddate = homepageInfo.endDate.split('T')
            const sdate = startdate[0].replace(/-/g, "/");
            const edate = enddate[0].replace(/-/g, "/");
            
            const sdates = startdate[0].split('-')
            const sdateyy = sdates[0];
            const sdatemm = sdates[1];
            const sdatedd = sdates[2];

            const edates = enddate[0].split('-');
            const edateyy = edates[0];
            const edatemm = edates[1];
            const edatedd = edates[2];

                        
            setStartDate(homepageInfo.startDate);
            setEndDate(homepageInfo.endDate);

            var date1 = new Date();
            var date2 = new Date(JSON.stringify(sdate));

            // To calculate the time difference of two dates
            var Difference_In_Time = date2.getTime() - date1.getTime();
      
            // To calculate the no. of days between two dates
            var Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
            setDaystoExam(Difference_In_Days + 1);


            //console.log("start date: ", startDate, "end date: ", endDate);
            
            setLoading(false)
            //setHomepageInfo(res);          
        })
        
    }, [id])
    return (
        <div style={{maxWidth: "13400px",backgroundColor: "whitesmoke"}}>
            <div className="header">
                
            </div>
            <div className="announce-download-app">
                <p>Use our mobile platform, to access and download past exams questions and solutions</p>
                <a href="https://ayosacademystorage.blob.core.windows.net/videotutorials/com.ogabook.app.apk" style={{alignText: "center", color: 'white'}} download>Download <span style={{color: "orangered"}}>Oga</span><span style={{color: "dodgerblue"}}>Book</span> from Playtore for offline usage </a> 
            </div>
            {loading? <>
            Loading....
            </>: <>
                <div className="days-to-exam">
                    <div> {homepageInfo.numberOfDaysToExamination} {/*<Moment durationFromNow>{startDate}</Moment> */}</div>
                    <div>{homepageInfo.examinationTitle}</div> 
                    <div> <Moment format="DD/MM/YYYY">{startDate}</Moment> ___ <Moment format="DD/MM/YYYY">{endDate}</Moment></div>
                </div>
                <div className="olympiad-announcement">
                    <div>
                        <div className="olympiad-announcement-img">
                            <img src={homepageInfo.howToUseOgaBookVideoUrl} height={360} />
                        </div>
                        <div className="olympiad-announcement-text">
                            {homepageInfo.videoTitle}<br/>
                            <i>{homepageInfo.announcementDescription}</i>
                        </div>
                        <span className="spanclr"></span>
                    </div>
                </div>
                
                <br/><br/>

                {/**https://ayosacademystorage.blob.core.windows.net/videos/Carlin.mp4
                 * 
                 https://www.youtube.com/watch?v=ysz5S6PUM-U
                */}
                <br/><br/>

    <br/><br/><br/>

                <div className="bottom-page-footer">
                    <div>
                        <h2>CONTACT US!</h2>{console.log(homepageInfo)}
                        <span>Email: {homepageInfo.emailContact}</span><br/>
                        <span>Phone Line 1: {homepageInfo.line1ContactWithWhatsApp}</span><br/>
                        <span>Phone Line 2: {homepageInfo.line2Contact} (WhatsApp, call, sms)</span>
                    </div>
                </div>
                </>
            }
            
            
        </div>
    );
}

export default HomePage;