import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './useCountdown';
import './timer.css'

const ExpiredNotice = () => {
  
  return (
    <div className="expired-notice">
      <span>Expired!!!</span>
      <p>Time allocated for the exam has been exceeded!</p>
      
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      
      <div className="countdown-link">
        <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={'Mins'} isDanger={minutes <= 5} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
      </div>
    </div>
  );
};


const CountdownTimer = ({ targetDate, handleSubmitAnswersFn, isAnswerSubmitted, stopExam }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds === 0) {    
    handleSubmitAnswersFn(true); 
    return <ExpiredNotice />       
      
  } if(days + hours + minutes + seconds < 0){
      //If the countdown is reached, do nothing.
      if(isAnswerSubmitted){
        return <ExpiredNotice /> 
      }
       
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
