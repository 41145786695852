import React, {useState, useEffect} from 'react';
import {localurl} from '../constants';
import { useParams } from "react-router-dom";
import axios from "axios";
import {Container, Alert, Button, Row, Col, Spinner, Modal} from "react-bootstrap";
import Pagination from 'react-bootstrap/Pagination';
import {getQuizUsingCode, sendQuizSolutionsToBackEnd, sendUserQuizToBackEnd} from '../MYAPI';
import Mcq from '../mcq/Mcq';
import { Pie } from 'react-chartjs-2';
import {Chart, ArcElement, Tooltip, Legend} from 'chart.js'
import CountdownTimer from '../timer/CountdownTimer';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

Chart.register(ArcElement, Tooltip, Legend);

function ActiveOlympiad(){
    const [mcqs, setMcqs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [mcqsPerPage, setMcqsPerPage] = useState(10);
    const [score, setScore] = useState(0);
    const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
    const [tooltipOpen, settooltipOpen] = useState(false);
    const [loadingReport, setloadingReport] = useState(true);
    const [pastpaperTitle, setPastPaperTitle] = useState("")
    const [questionNumber, setquestionNumber] = useState('');
    const [tick, setTick] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const [currentMcqs, setCurrentMcqs] = useState([]);
    const [unansweredCount, setUnansweredCount] = useState(0);
    const [wrongCount, setWrongCount] = useState(0);
    const [stopExam, setStopExam] = useState(true);
    const [quiz, setQuiz] = useState(null);
    
    const [report, setReport] = useState('');
    const [title, setTitle] = useState("");
    const [quizStartTime, setQuizStartTime] = useState(new Date().getTime());

    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);

    const name = accounts[0] && accounts[0].name;
    
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [dataPie, setDataPie] = useState(
        {   labels: ["Red", "Green", "Orange"],
            label: "Score Chart",
            datasets: [
            {
                data: [score, 0, 0],
                backgroundColor: ["#F7464A", "#46BFBD", "#FDB45C"  ],
                hoverBackgroundColor: ["#FF5A5E", "#5AD3D1", "#FFC870"]
            } ]
        });
    

    let { quizCode} = useParams();

    useEffect(() => {
        setLoading(true);
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            //console.log("AccessToken from Active Olympiad: ", accessToken)
            setAccessToken(response.accessToken);
            getQuizUsingCode(quizCode, response.accessToken,
                (res) => {
                                    
                    setMcqs(res);
    
                    const quiz = JSON.parse(localStorage.getItem('quiz'));
    
                    if(quiz){
                        //const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
                        const ONE_MINUTE = quiz.durationInMinutes * 60 * 1000;
                        const NOW_IN_MS = new Date().getTime();
    
                        const dateTimeAfterThreeDays = NOW_IN_MS + ONE_MINUTE;
    
                        setQuizStartTime(dateTimeAfterThreeDays);
                        
                        setQuiz(quiz);
    
                        setTitle(quiz.title);
    
                        //localStorage.removeItem('quiz');
                    }
                    setLoading(false);
                }, 
            (err) => {
                console.log("Error: ", err)
            })
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                setAccessToken(response.accessToken);
                getQuizUsingCode(quizCode, response.accessToken,
                    (res) => {
                                        
                        setMcqs(res);
        
                        const quiz = JSON.parse(localStorage.getItem('quiz'));
        
                        if(quiz){
                            //const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
                            const ONE_MINUTE = quiz.durationInMinutes * 60 * 1000;
                            const NOW_IN_MS = new Date().getTime();
        
                            const dateTimeAfterThreeDays = NOW_IN_MS + ONE_MINUTE;
        
                            setQuizStartTime(dateTimeAfterThreeDays);
                            
                            setQuiz(quiz);
        
                            setTitle(quiz.title);
        
                            //localStorage.removeItem('quiz');
                        }
                        
        
                        setLoading(false);
                    }, 
                (err) => {
                    console.log("Error: ", err)
                })
            });
        });
        
        
    }, [accessToken]);


    useEffect(() => {
        //console.log("I am tick: ", tick);
    }, [tick])

    useEffect(() => { 
        console.log("");
    }, [isAnswerSubmitted])

    useEffect(() => {
        //console.log("");
    }, [showExplanation])

    useEffect(() => {
        console.log("...");
    }, [activePage])

    useEffect(() => {
        //console.log("...");
    }, [dataPie])

    useEffect(() => {
        //console.log("...");
    }, [stopExam])

    const handleChange = (m, option) => {
        /*
        console.log("Selected MCQ: ", m);
        console.log("Option Selected: ", option);
        console.log("MCQ Position: ", m.position);*/
        changeOptionSelectedValueFxn(option);
        setTick(!tick);
    }

    const handlePageChange = (pageNumber) => {
        console.clear();
        //console.log("Page Number: ", pageNumber);
        setCurrentMcqs(mcqs.slice(indexOfFirstMcq, indexOfLastMcq))
        setActivePage(pageNumber);
    }

    const handleImgOptionChange = (m, option) => {
        changeOptionSelectedValueFxn(option);
        setTick(!tick);
    }

    const handlegetCorrectionAnwerList = (m) => {
        let text = "";
        if(m.answer > 0){
            switch(m.answer) {
                case 1:
                    text = "A";
                  break;
                case 2:
                    text = "B";
                  break;                          
                case 3:
                    text = "C";
                break;
                case 4:
                    text = "D";
                break;
                case 5:
                    text = "E";
                    break;
                default:
                  // code block
              } 
        }else{
            if(m.mcqOptions.length > 0){
                var options = m.mcqOptions;
                for(let i = 0; i<m.answers.length; i++){
                    var index = m.answers[i];
                    if(i>0 && i < m.answers.length + 2){
                        text += ", ";
                    }
                    
                    switch(index) {
                        case 1:
                            text += "A";
                          break;
                        case 2:
                            text += "B";
                          break;                          
                        case 3:
                            text += "C";
                        break;
                        case 4:
                            text += "D";
                        break;
                        case 5:
                            text += "E";
                            break;
                        default:
                          // code block
                    }
                    
                }
            }else{
                var options = m.mcqOptionImages;
                for(let i = 0; i<m.answers.length; i++){
                    var index = m.answers[i];
                    if(i>0 && i < m.answers.length + 2){
                        text += ", ";
                    }

                    switch(index) {
                        case 1:
                            text += "A";
                          break;
                        case 2:
                            text += "B";
                          break;                          
                        case 3:
                            text += "C";
                        break;
                        case 4:
                            text += "D";
                        break;
                        case 5:
                            text += "E";
                            break;
                        default:
                          // code block
                      }

                    
                }
            }
        }
        if(m.answer === 0){
            text += "."
        }
        return text;
    }

    const handleSendReport = (m) => {
        alert("About to send some reports: ", m.question);
    }
    const showExplanationFn = (m) => {
        m.showExplanation = !m.showExplanation;
        setShowExplanation(!showExplanation);
        
    }

    function changeOptionSelectedValueFxn(option){
        if(option.optionSelectedValue === null){
            option.optionSelectedValue = true;
        }else {
            option.optionSelectedValue = !option.optionSelectedValue;
        }
        
    }
    
    const handleSubmitAnswers = (fromTimer) => {
        setLoading(true);
        if(!isAnswerSubmitted){
            //Marking
        for(let i = 0; i<mcqs.length; i++){
            mcqs[i].isAnswerSubmitted = true;

            if(mcqs[i].answer > 0){
                //1. if more than one option is ticked, the answer is wrong
                //2. if m.answer - 1 === index of option selected option
                var options = mcqs[i].mcqOptions;

                if(options && options.length > 0){
                    var numberOfSelectedOptions = mcqs[i].mcqOptions.filter(x => x.optionSelectedValue === true);
                    
                    if(numberOfSelectedOptions.length > 1){
                        //If more than one option was selected, then wrong answer since ONLY one option is CORRECT.
                        mcqs[i].answerStatus = "wrong";
                        mcqs[i].color = "red";
                    }else if(numberOfSelectedOptions.length === 1){
                        //if ONLY ONE OPTION
                        var selectedPosition = mcqs[i].answer - 1;
                        if(mcqs[i].mcqOptions[selectedPosition].optionSelectedValue){
                            mcqs[i].answerStatus = "correct";
                            mcqs[i].color = "green";
                        }else{
                            mcqs[i].answerStatus = "wrong";
                            mcqs[i].color = "red";
                        }
                        
                    }else{
                        mcqs[i].answerStatus = "unanswered";
                        mcqs[i].color = "yellow";
                    }
                }else{ //Dealing with image options
                    var numberOfSelectedOptions = mcqs[i].mcqOptionImages.filter(x => x.optionSelectedValue === true);
                    
                    if(numberOfSelectedOptions.length > 1){
                        //If more than one option was selected, then wrong answer since ONLY one option is CORRECT.
                        mcqs[i].answerStatus = "wrong";
                        mcqs[i].color = "red";
                    }else if(numberOfSelectedOptions.length === 1){
                        //if ONLY ONE OPTION
                        var selectedPosition = mcqs[i].answer - 1;
                        if(mcqs[i].mcqOptionImages[selectedPosition].optionSelectedValue){
                            mcqs[i].answerStatus = "correct";
                            mcqs[i].color = "green";
                        }else{
                            mcqs[i].answerStatus = "wrong";
                            mcqs[i].color = "red";
                        }
                        
                    }else{
                        mcqs[i].answerStatus = "unanswered";
                        mcqs[i].color = "yellow";
                    }
                }

            }

            if(mcqs[i].answer === 0){
                
                var isCorrect = true; //Assume the answer is true
                mcqs[i].answerStatus = "correct";
                
                //If the question contains options
                if(mcqs[i].mcqOptions && mcqs[i].mcqOptions.length > 0){
                    var options = mcqs[i].mcqOptions.filter(x => x.optionSelectedValue === true);
                    var ans = mcqs[i].answers;
                    
                    //If number of selected options = number of responses privided
                    if(ans.length === options.length) {
                        //Count same ===> possibility of matching selected options
                        for(let j = 0; j<ans.length; j++){
                            var optionPosition = ans[j] - 1;

                            if(mcqs[i].mcqOptions[optionPosition].optionSelectedValue === false){
                                isCorrect = false;
                                mcqs[i].answerStatus = "wrong";
                                mcqs[i].color = "red";
                            }
                        }
                    }else{
                        isCorrect = false;
                        mcqs[i].answerStatus = "wrong";
                        mcqs[i].color = "red";
                    }
                    if(options.length === 0){
                        mcqs[i].answerStatus = "unanswered";
                        mcqs[i].color = "yellow";
                    }
                    //Question contains image options
                }else{
                    if(mcqs[i].mcqOptionImages && mcqs[i].mcqOptionImages.length > 0){
                        var options = mcqs[i].mcqOptionImages.filter(x => x.optionSelectedValue === true);;
                        ans = mcqs[i].answers;

                        if(mcqs[i].answers.length === options.length) {
                            //Count same ===> possibility of matching selected options
                            for(let j = 0; j<ans.length; j++){
                                var optionPosition = ans[j] - 1;
                                if(mcqs[i].mcqOptionImages[optionPosition].optionSelectedValue == false){
                                    isCorrect = false;
                                    mcqs[i].answerStatus = "wrong";
                                    mcqs[i].color = "red";
                                }
                            }
                        }else{
                            isCorrect = false;
                            mcqs[i].answerStatus = "wrong";
                            mcqs[i].color = "red";
                        }

                        if(options.length === 0){
                            mcqs[i].answerStatus = "unanswered";
                            mcqs[i].color = "yellow";
                        }
                    }

                }

            }
            
            mcqs[i].showExplanation = false;
        }
        
        //Submit answers to backend
        //Loading... the ux

        var subjectId = 0;
        var pastpaperId = "";
        var pastpaperNumber = 1;
        var topicNumber = 0;
        var questionPosition = -1;
        var paperYear =  "";
        var answerStatus = 3;
        var pastpapersId = "";
        var answersCollection = "";

        for(let i = 0; i<mcqs.length; i++){
            
            subjectId = parseInt( mcqs[i].pastPaperId.slice(0,3) );
            pastpaperId = mcqs[i].pastPaperId;
            
            
            questionPosition = mcqs[i].position;
            paperYear =  "";
            if(mcqs[i].answerStatus === "correct"){
                answerStatus =  2
            }else if(mcqs[i].answerStatus === "wrong"){
                answerStatus = 1;
            }else{
                answerStatus = -1;
            }
            answersCollection = answersCollection + questionNumber.toString() + "-" + answerStatus.toString() + ",";            
        }

        //console.log("Submitted before Error: ", subjectId, pastpaperId, pastpaperNumber, topicNumber, questionPosition, paperYear, answerStatus);
        sendQuizSolutionsToBackEnd(pastpaperId, answersCollection, accessToken,
            (res) => {
                //console.log("");
            },
            (err) => {
                //console.log("");
            } 
        );

        if(pastpapersId.length < 1){
            pastpapersId = pastpaperId;
        }
                    
        const right = mcqs.filter(x => x.answerStatus === "correct").length;
        const wrong = mcqs.filter(x => x.answerStatus === "wrong").length;

        if(quiz && quiz.isRightWrong){            
            setScore(right - wrong); 
            //Save on UserQuiz Table
            sendUserQuizToBackEnd(pastpapersId, parseInt(right - wrong), accessToken,
                (res) => {
                    //console.log("");
                },
                (err) => {
                    //console.log("");
                } );          

        }else{
            setScore(mcqs.filter(x => x.answerStatus === "correct").length);
            //Save on UserQuiz Table
            sendUserQuizToBackEnd(pastpapersId, parseInt(right), accessToken,
                (res) => {
                    //console.log("");
                },
                (err) => {
                    //console.log("");
                } );
        }

        
        
        setWrongCount(mcqs.filter(x => x.answerStatus === "wrong").length);
        setUnansweredCount(mcqs.filter(x => x.answerStatus === "unanswered").length);

        //setPieChartData(mcqs.filter(x => x.answerStatus === "correct").length, mcqs.filter(x => x.answerStatus === "wrong").length, mcqs.filter(x => x.answerStatus === "unanswered").length);
        const data = {
            labels: ['Correct', 'Wrong', 'Skipped'],
            datasets: [
                {
                label: '# of Score',
                data: [mcqs.filter(x => x.answerStatus === "correct").length, mcqs.filter(x => x.answerStatus === "wrong").length, mcqs.filter(x => x.answerStatus === "unanswered").length],
                backgroundColor: [
                    "Green",
                    "Red",
                    "Orange"
                ],
                borderColor: [
                    'White',
                    'White',
                    'White'
                ],
                borderWidth: 1,
                },
            ],
            };          
         
        setDataPie(data);       
        setIsAnswerSubmitted(true);
        //localStorage.removeItem('quiz');

        setShow(true);
        }

        setLoading(false);       
        
    }

    const setPieChartData = (c, w, u) => {
        //var score = mcqs.filter(x => x.answerStatus === "correct").length;
        

        const dataValue = [score, wrongCount, unansweredCount];
        console.log("DataValue; ",dataValue);

        const data = {
            labels: ['Correct', 'Wrong', 'Skipped'],
            datasets: [
                {
                label: '# of Score',
                data: [c, w, u],
                backgroundColor: [
                    "Green",
                    "Red",
                    "Orange"
                ],
                borderColor: [
                    'White',
                    'White',
                    'White'
                ],
                borderWidth: 1,
                },
            ],
            };          
            
        setDataPie(data);

    }
    

    

    const pageNumbers = [];
    
    for (let number = 1; number <= Math.ceil(mcqs.length / mcqsPerPage); number++) {
        pageNumbers.push(
            <Pagination.Item key={number} page={activePage} active={number === activePage}
            onClick={() => handlePageChange(number)}
            >
            {number}
            </Pagination.Item>,
        );
        }
    const handleCloseModal = () => setShow(false);
    const handleShowModal = () => setShow(true);
                
    const indexOfLastMcq = activePage * mcqsPerPage;
    const indexOfFirstMcq = indexOfLastMcq - mcqsPerPage;
    const currentMcqsList = mcqs.slice(indexOfFirstMcq, indexOfLastMcq);

    const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
    const ONE_MINUTE = 1 * 60 * 1000;
    const NOW_IN_MS = new Date().getTime();

    const dateTimeAfterThreeDays = NOW_IN_MS + ONE_MINUTE; // THREE_DAYS_IN_MS;
    
    return(
        <div>
            <div>
                {mcqs.length > 0 ? <>
                    <Alert variant='info'>
                    <p>{title}</p>
                        <p>{isAnswerSubmitted? <> Score: <b>{score} / {mcqs.length}</b> </>: null}</p>
                    </Alert>
                </> : <></>}
                
                {mcqs.length > 0 ? <> <CountdownTimer targetDate={quizStartTime} handleSubmitAnswersFn={handleSubmitAnswers} isAnswerSubmitted={isAnswerSubmitted} stopExam={stopExam} /></> : <> {!loading? <>No Questions Found. <b>You can not write a quiz more than once</b></> : <></>}  </>}
                
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {loading? <>
                    <Spinner animation="border" variant="primary" />
                </> : <>
                <Pagination> {pageNumbers} </Pagination>
                </>}
            </div>
            {
                currentMcqsList.map((mcq) => {
                    return(
                        <div key={mcq.id}>
                                                       
                            <Mcq mcq={mcq} key={mcq.id}  updateMcqList={handleChange} updateMcqImgList={handleImgOptionChange} getCorrectionAnwerList={handlegetCorrectionAnwerList}
                            sendReportFn={handleSendReport} 
                            showExplanationFxn={showExplanationFn}/>
                        </div>
                    )
                    
                })
            }
                 <Modal
                    show={show}
                    onHide={handleCloseModal}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{width: "100%", padding:"10px", border:'1px solid gray'}}>

                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: "50%", backgroundColor: "whitesmoke", height: "50px", width: "50px", margin: "auto", color: "whiteSmoke", backgroundColor:"brown", position: "relative", top:"-35px", border: "1px solid yellow"}}>
                                <h1><b> {accounts[0] && accounts[0].name.slice(0,1).toUpperCase()} </b></h1>
                            </div>
                            <h5 style={{textAlign: "center", position: "relative", top:"-25px"}}> {accounts[0] && accounts[0].name} </h5>
                            <hr/>

                            <div>
                                <h6 style={{textAlign: "center" }}>{title}</h6>
                                <div style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div style={{width: "120px", border: "1px solid gray", padding: "4px", marginRight: "20px", borderRadius: '10px', textAlign: 'center', backgroundColor: "#2F4F4F", color: "whitesmoke"}}>
                                        SCORE<br/>
                                        <b>{score}</b><br/>
                                        Out of {mcqs.length}
                                    </div>
                                    <div style={{width: "120px", border: "1px solid gray", padding: "4px", borderRadius: '10px', textAlign: 'center', backgroundColor: "#663399", color:"whitesmoke"}}>
                                        RANK<br/>
                                        <b>---</b><br/>
                                        Out of ---
                                    </div>
                                </div>
                                <div>
                                    <hr />
                                        <p style={{textAlign: "center"}}>
                                            <b>Question Analysis</b>
                                        </p>                                        
                                    <hr />
                                    {mcqs.map(m => {
                                        return (
                                            <span key={m.id} >
                                                {/*Correct*/}
                                                {m.answerStatus === "correct" ? <>
                                                {m.position < 10? <>
                                                 <span style={{backgroundColor:"green", borderRadius: "50%", width:"40px", height: "40px", color:"white", padding: '5px', margin: "4px", display: "inline-block", textAlign: "center"}}>{m.position} </span>
                                                </>: <>
                                                    <span style={{backgroundColor: "green", borderRadius: "50%", width:"40px", height: "40px", color:"white", padding: "4px", margin: '4px', display:"inline-block", textAlign: "center"}}>{m.position}</span>
                                                </>}
                                                 </> : <>
                                                 {/*Wrong*/}
                                                 {m.answerStatus === "wrong" ? <>
                                                 {m.position < 10? <>
                                                 <span style={{backgroundColor:"red", borderRadius: "50%", width:"40px", height: "40px", color:"white", padding: '5px', margin: "4px", display: "inline-block", textAlign: "center"}}>{m.position} </span>
                                                </>: <>
                                                    <span style={{backgroundColor: "red", borderRadius: "50%", width:"40px", height: "40px", color:"white", padding: "4px", margin: '4px', display:"inline-block", textAlign: "center"}}>{m.position}</span>
                                                </>}
                                                 </> : <>
                                                 {/*UnAttempted*/}
                                                 {m.position < 10? <>
                                                 <span style={{backgroundColor:"orange", borderRadius: "50%", width:"40px", height: "40px", color:"white", padding: '5px', margin: "4px", display: "inline-block", textAlign: "center"}}>{m.position} </span>
                                                </>: <>
                                                    <span style={{backgroundColor: "orange", borderRadius: "50%", width:"40px", height: "40px", color:"white", padding: "4px", margin: '4px', display:"inline-block", textAlign: "center"}}>{m.position}</span>
                                                </>}
                                                 
                                                 </>}
                                                 
                                                 </>}
                                                
                                            </span>
                                        )
                                    })}
                                    
                                </div>
                                <div>
                                    <hr />
                                    {quiz && quiz.isRightWrong? <>
                                        <span style={{color: "dodgerblue"}}><b>	&#9083; </b> &nbsp; &nbsp; FINAL SCORE:  &nbsp;  <b>{score}</b> </span><span style={{fontSize: "0.6em"}}><i>(Right - Wrong)</i></span> <br/> 
                                        <span style={{color: "green"}}>&#10003; &nbsp; &nbsp; Correct:  &nbsp;  <b>{score + wrongCount}</b></span> <br/> 
                                    </> : <>
                                        <span style={{color: "green"}}>&#10003; &nbsp; &nbsp; Correct:  &nbsp;  <b>{score + wrongCount}</b></span> <br/>
                                    </>}
                                    
                                    <span style={{color: "red"}}>&#10060;  &nbsp; &nbsp; Incorrect:  &nbsp; <b>{wrongCount}</b></span> <br/> 
                                    
                                    <span style={{color: "orange"}}> <b style={{fontSize: "1.2em"}}>&gt;</b> &nbsp;  &nbsp; Skipped: &nbsp; <b>{unansweredCount}</b></span> <br/>
                                                                    
                                    <hr />
                                </div>
                                <div style={{ margin: "auto"}}>
                                  {dataPie? <Pie data={dataPie} options={{ responsive: true }} redraw={true}/> : null}
                                </div>
                            </div>
                        </div>
                    
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    {/*<Button variant="primary">Understood</Button>*/}
                    </Modal.Footer>
                </Modal>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {loading? <>
                    
                </> : <>
                <Pagination> {pageNumbers} </Pagination>
                </>}
            </div>
            
            <div style={{display: 'flex', alignItems: 'end', justifyContent: 'end', marginBottom: "80px"}}>
                {loading? <>
                    
                </> : <>
                {mcqs.length > 0 ? <>
                    <Button type='button' disabled={isAnswerSubmitted} className='primary' onClick={handleSubmitAnswers}>SUBMIT ANSWERS</Button>
                </> : <> </>}
                
                </>}
            </div>
        </div>
    );
}

export default ActiveOlympiad;