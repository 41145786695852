import React, { useEffect, useState } from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleCheck, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {GetOlympiadQuizResultDtos} from '../MYAPI'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from "react-bootstrap";


function OlympiadResultStudentList(props){
    const [results, setOlympiadsResults] = useState([])
    const [loading, setLoading] = useState(false);
    const [pastpaperId, setPastperId] = useState('');


    let checkIcon = <FontAwesomeIcon style={{color: "#22A370", fontSize: "2.21em" }} icon={faCircleCheck}  /> 
    let arrowIcon = <FontAwesomeIcon style={{color: "gray", fontSize: "0.81em" }} icon={faChevronRight}  /> 

    let navigate = useNavigate();

    const { instance, accounts } = useMsal();  
    let {id} = useParams();

    useEffect(() => {
        setLoading(true);
        //console.log('pastpaperId: ', id)
        if(accounts.length > 0){
            const request = {
                ...loginRequest, 
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then((response) => {
                GetOlympiadQuizResultDtos(id, response.accessToken, (res) => {
                    //console.log("Olympiads: ", response);
                    setOlympiadsResults(res)
                    setLoading(false);
                }).catch((error) => {
                    //console.log("Error: ", error)
                })
            });
        }else{
            GetOlympiadQuizResultDtos(id, (response) => {
                //console.log("Olympiads: ", response);
                setOlympiadsResults(response)
                setLoading(false);
            }).catch((error) => {
                //console.log("Error: ", error)
            })
        }
    }, [])

    function handleShow(result) {
        if(result.isWinner){
            navigate(`/olympiad/student-quiz-winner-award/${result.pastPaperId}/${result.userId}`)
        }else{
            alert("You can ONLY view information about students who are winners of a contest!")
        }
    }

    return(
        <div>
            {loading? <>
                <div style={{width: "50%", margin: "auto", paddingTop: "60px"}}>
                            <Spinner animation="border" variant="primary" />
                </div>
            </>: <>
            {results && results.length > 0? <>
                {results.map((result) => {
                    return(
                        <div style={{cursor: "pointer"}} key={result.rank} onClick={() => handleShow(result)}>
                            <div style={{display: "flex", border: "1px solid #DCE3EC", height: "95px", backgroundColor: "white", borderRadius: "0.5em", margin: "0.3em", width: "100%", padding: "6px" }}>
                    
                                <div style={{width: "20%", display: "flex", alignItems: "center"}}>
                                    
                                    <div style={{height: "40px", width: "40px", borderRadius: "50%", backgroundColor: "#22A370", color: "white", textAlign: "center",lineHeight: "40px"}}> <b>{result.rank}</b> </div>
                                    
                                </div>

                                <div style={{width: "43%", fontSize: "0.8em", display: "flex", alignItems: "center"}}>
                                    
                                    <div style={{padding: "10px", fontSize: "0.8em", textAlign: "left"}}>
                                        <b>{result.username? <>{result.username}</>: <>Unknown User</>}</b> <br/> 
                                        <span>Region: {result.regionOfOrigin}<br/>
                                            Town: {result.town}<br/> 
                                            School: {result.school}
                                        </span> 
                                    </div>              

                                </div>
                                <div style={{display: "flex", width: "43%",  alignItems: "end"}}>
                                    <div style={{fontSize: "0.7em", textAlign: "center", padding: "6px"}}>
                                    <b>Rank</b><br/>
                                    {result.rank} out of {result.totalParticipants}<br/>
                                        <div style={{height: "1px", backgroundColor: "lightgray"}}></div>
                                        {result.isWinner? <>
                                            <span style={{color: "#32B4B8", fontWeight: "bold"}}> Star Winner - Score: {result.score}<br/> Price: {result.price}  </span>
                                        </>: <>
                                        <span style={{color: "red"}}> Try next quiz again - Score: {result.score}<br/> Price: --- </span>
                                        </>}
                                    </div>
                                </div>

                                <div style={{display: "flex", width: "5%",  alignItems: "end"}}>
                                    <div style={{margin: "auto", width: "50%"}}>
                                    {arrowIcon}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            
            </> : <></>}
            </>}
            
        </div>
    )
}

export default OlympiadResultStudentList;