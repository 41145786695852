import React from 'react';
import Exam from './Exam';


function Exams(props){

    const exam = props.exam;
    const examstyle = {
        color: exam.categoryTextColor,
        backgroundColor: exam.categoryBgColor,
        padding: "10px",
        fontFamily: "Arial",
        fontWeight: "Bold", 
        display: "block"
      };
      const displayStyle = {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap ",
        justifyContent:"center"
      };
      const badge = {
        maxWidth: "270px",
      }
    return(
        <div>
            {exam ? <>
                
                <div>
                    {exam.examCategoryDtos && exam.examCategoryDtos.length > 0 ? 
                    
                    <div>
                        <div style={examstyle}>{exam.categoryName}</div>

                        <div style={displayStyle}>
                            {exam.examCategoryDtos.map((e) => {
                                return(
                                    <div key={e.id} style={badge}>
                                        <Exam exam={e}/>
                                    </div>
                                )
                            })}
                        </div>

                        
                    </div> : <>No content yet</>}
                </div>
                

            </>:<></>}
        </div>
    );
}

export default Exams;