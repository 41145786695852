import React, { useEffect, useState, useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton"; 
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Container } from "react-bootstrap";
import { UserContext } from "./UserContext";
import { NavigationBar } from "./NavigationBar";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
 export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const [username, setUsername] = useState("");
    const [isLogout, setIsLogout] = useState(true);

    const {user, setUser} = useContext(UserContext);

    useEffect(() => {
        const name = localStorage.getItem('Name');
        const usrname = localStorage.getItem('Name');
            
        setUsername(usrname);

    }, [isLogout]);

    return (
        <>
        {/**
         * 
         <Navbar bg="dark" variant="dark" expand="lg">                
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img alt="" src="/logo192.png" width="30" height="30" className="d-inline-block align-top"
                        />{' '}
                    <span style={{"color": "orangered", "fontWeight":"bold"}}>Oga</span><span  style={{"color": "dodgerblue", "fontWeight":"bold"}}>Book</span> 
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/">Home</Nav.Link>

                            <NavDropdown title="Explore" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/examination-gce">GCE General</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/examination-concours">CONCOURS</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/dashboard">Study</Nav.Link>
                            <NavDropdown title="Download Past Papers" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">GCE O Level</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">GCE A Level</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Others</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Mocks</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav>
                            <Nav.Link> {isLogout? username : <></>} </Nav.Link>
                            <Nav.Link href="#deets">Books</Nav.Link>
                            <Nav.Link eventKey={2} href="/olympiad">Olympiads</Nav.Link>
                            {isAuthenticated ? <>
                                <NavDropdown title="Sign Out" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Profile</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3"> { isAuthenticated ? 
                                        <span> <SignOutButton setIsLogoutFn={setIsLogout} /> </span>
                                                        : 
                                        <SignInButton setIsLogoutFn={setIsLogout}/> } 
                                 </NavDropdown.Item>
                                </NavDropdown>
                            </>: <> 
                            <NavDropdown title="Sign In" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Profile</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3"> { isAuthenticated ? 
                                        <span> <SignOutButton  setIsLogoutFn={setIsLogout} /> </span>
                                                        : 
                                        <SignInButton setIsLogoutFn={setIsLogout}/> } 
                                 </NavDropdown.Item>
                            </NavDropdown>
                            </>}                            
                            
                        </Nav>
                    </Navbar.Collapse>


                </Container>

                
                
            </Navbar>
            
         */}
            <NavigationBar />
            
            {props.children}
            
            
        </>
    );
};