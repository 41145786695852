import React, { useEffect, useState } from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {getSubjectDtosForGivenExamId} from '../MYAPI';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useNavigate } from 'react-router-dom';
import ExamSubjectCard from "../examinantions/ExamSubjectCard";
import { Spinner } from "react-bootstrap";
import Lottie from "react-lottie-player";
import constructionMen from '../asserts/error-not-found.json'



function DownloadIndex(){
    const [loading, setLoading] = useState(false);
    const [subjectDtos, setSubjectDtos] = useState([]);

    let {path} = useParams();
    //let {examId} = useParams();
    const navigate = useNavigate();
    const {pathname} = useLocation();

    useEffect(() => {
        setLoading(true);
        let examId = 0;
        const url = JSON.stringify(pathname.toString())
        //console.log("Url before: ", url.slice(22, url.length - 1 ))
        if( url.slice(22, url.length - 1 ) == "olevelgeneral"){
            examId = 500;
            //console.log("O Level path: ")
        }else
        if( url.slice(22, url.length - 1 ) == "alevelgeneral"){
            examId = 700;
            
        }
        if( url.slice(22, url.length - 1 ) == "others"){
            examId = 300;
            //alert(pathname.toString())
        }
        //console.log("ExamID: ", examId);
       
        getSubjectDtosForGivenExamId(examId, (res) => {
         let notEnrolledSubjects = res.filter(x => x.isNotEnroll === true )
         
         setSubjectDtos(notEnrolledSubjects);
         setLoading(false)
        })
 
     }, [] );
 
     function showLoading(subjectId){
         for(var i = 0; i<subjectDtos.length; i++){
             if(subjectDtos[i].subjectId == subjectId){
                 subjectDtos[i].isLoading = true;
             }
         }
     }
     
    function LoadPastExamsDownloadablePapers(subjectId){
        showLoading(true); 
        setLoading(true);
        //console.log("subject select: ", subjectId)
        navigate(`/download/pastpapers-list/${subjectId}`)
        setLoading(false);
    }
    const displayStyle = {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent:"center",
      };

    const style = {
        display: "block"
    }

    return(
        <div style={{maxWidth: "760px", margin: "auto", backgroundColor: "whitesmoke", padding: "0 10px 10px 10px"}}>
            {loading? <div style={{display: 'flex', justifyContent: "center", alignItems: "center"}}>
                <Spinner animation="border" variant="primary" />
            </div>: <>
            
            </>}
            {subjectDtos && subjectDtos.length > 0 ? <>
            <div style={displayStyle}>
                
                {subjectDtos.map((subjectdto) => {
                    return(
                        <div key={subjectdto.subjectId} style={style}>
                            <ExamSubjectCard subjectdto={subjectdto} LoadPastExamsDownloadablePapersFn={LoadPastExamsDownloadablePapers} isFromDownload={true} title='View Pastpapers' />
                        </div>
                    )
                })}
            </div>
            
            </>:<div style={{display: "flex", alignItems: "center", justifyContent: "center"}}> <br/><br/><br/><br/>
                <Lottie loop={true} play={true} animationData={constructionMen}/>
                <h3> No Subjects Found!! Pleasse try again later!!! </h3>
            </div>}
        </div>
    )
}

export default DownloadIndex;