import React, { useEffect, useState } from "react";
import {  GetGcepastpapersPdfDownloads } from "../MYAPI";
import { useParams } from "react-router-dom";
import DownloadCard from "./DownloadCard";
import Lottie from "react-lottie-player";
import constructionMen from '../asserts/error-not-found.json'
import { Spinner } from "react-bootstrap";
import FileDownload from 'js-file-download';
import axios from "axios";
import fileDownload from "js-file-download";


function DownloadListPage(){
    const [loading, setLoading] = useState(false);
    const [pdfPapers, setPdfPapers] = useState([]);
    const [zipFileUrl, setZipFileUrl] = useState('');
    

    let {subjectId} = useParams();

    useEffect(() => {
        setLoading(true);

        GetGcepastpapersPdfDownloads(subjectId, (res) => {
            //console.log("received: ", res);
            if(res && res.length > 0){
                for(var i = 0; i<res.length; i++){
                    
                    for(var j = 0; j<res[i].downloadDtos.length; j++){
                        if(res[i].downloadDtos[j].zipFileUrl && res[i].downloadDtos[j].zipFileUrl.length > 0){
                            
                            console.log("zipFileUrl: ", res[i].downloadDtos[j].zipFileUrl);
                            res[i].zipFileUrl = res[i].downloadDtos[j].zipFileUrl;
                        }
                        res[i].downloadDtos[j].loading = false;
                        
                    }
                }

                setPdfPapers(res);
            }
            
            setLoading(false);
        })

    }, [])

    useEffect(() => {

    }, [pdfPapers]);
    
    function DownloadThisPastPaper(download) {
       console.log("Downloading this paper: ", download);
        
       let res = pdfPapers;
       for(var i = 0; i<res.length; i++){
            
            for(var j = 0; j<res[i].downloadDtos.length; j++){
                if(res[i].downloadDtos[j].id == download.id){                    
                    res[i].downloadDtos[j].loading = true;
                    
                }else{
                    res[i].downloadDtos[j].loading = false;
                }

            }
        }

        setPdfPapers(null);
        setPdfPapers(res);

        setZipFileUrl("state has changed" + download.id.toString());
        

        /*axios.get(download.url, {
            mode: 'no-cors',
            responseType: 'blob','Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }).then((res) => {
            fileDownload(res.data, title)
        })*/

        const link = document.createElement('a');
        link.href = download.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


    }

    function DownloadForWholePastPaper(url) {
        //console.log("Download the whole paper for this year: ", paperYear);

        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
    }
// className="left-part-icon-design" style={{border: "2px solid white", padding: "7px",margin: "10px", borderRadius: "1.6em 1%",     color: "white", width: "17%"}}
    return(
        <div className="main-container-div">
            {loading? <>
             <Spinner animation="border" variant="primary" />
            </> : <>
                {pdfPapers && pdfPapers.length > 0 ? <>
                
                {pdfPapers.map((paper) => {
                    return(
                        <div key={paper.paperYear}> 
                            {paper.downloadDtos && paper.downloadDtos.length? <>

                                <DownloadCard downloadDtos={paper.downloadDtos} paperYear={paper.paperYear}
                                 DownloadThisPastPaper={DownloadThisPastPaper}
                                 DownloadForWholePastPaper={DownloadForWholePastPaper}
                                 zipFileUrl={paper.zipFileUrl}
                                 />

                            </> : <></>}                           
                                         
                        </div>
                    )
                })}
                
                
                </> : <>
                
                <div style={{maxWidth: "760px", margin: "auto", backgroundColor: "whitesmoke", padding: "0 10px 10px 10px"}}>
                    <Lottie loop={true} play={true} animationData={constructionMen}/>
                    <h2 style={{textAlign: "center"}}> No past papers found! Try again later!</h2>
                </div>
                
                 </>}
            </>}
            
        </div>
    )
}

export default DownloadListPage;