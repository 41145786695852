import React from 'react';
import {Nav, Navbar, Dropdown,  DropdownButton, Button, NavDropdown } from 'react-bootstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest, b2cPolicies } from './authConfig';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';



export const NavigationBar = () => {
    const { instance, accounts, inProgress } = useMsal();
    const [loading, setLoading] = useState(false);
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }
   // console.log("NavigationBar - instance: ", instance)
    
    const navigate = useNavigate();

    const handleLoginPopup = () => {
        instance
            .loginPopup(loginRequest)
            .catch((error) => console.log(error));
    };

    const handleLogoutPopup = () => {
        instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
        });
    };
    
    const handleNavigateToDashboard = () => {
        setLoading(true);
        
        
        
        const request = {
            ...loginRequest, 
            //account: accounts[0]
        };

        if(accounts && accounts.length > 0){
            //User is login, now let's navigate
            navigate('/dashboard');
        }else{
            instance.acquireTokenPopup(request).then((resp) => {
                navigate('/dashboard');
            })
        }
    }

    const username = accounts[0] && accounts[0].name;

    
    return(
        <>
            <Navbar bg="dark" variant='dark' className='navbarStyle' expand="lg">
                <Navbar.Brand href="/">
                        <img alt="" src="/logo192.png" width="30" height="30" className="d-inline-block align-top"
                        />{' '}
                    <span style={{"color": "orangered", "fontWeight":"bold"}}>Oga</span><span  style={{"color": "dodgerblue", "fontWeight":"bold"}}>Book</span> 
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/">Home</Nav.Link>

                            <NavDropdown title="Explore" id="collasible-nav-dropdown" >
                                <NavDropdown.Item href="/examination-gce">GCE General</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/examination-concours">CONCOURS</NavDropdown.Item>
                            </NavDropdown>

                            {/**
                             * <Nav.Link href="/dashboard">Study</Nav.Link>
                             */}

                            <Button onClick={handleNavigateToDashboard} style={{backgroundColor: "transparent", border: "0", color: "gray", textAlign: "left"}}>Study</Button>

                            <NavDropdown title="Download Past Papers" id="collasible-nav-dropdown" >
                                <NavDropdown.Item href="/download/pastpapers/olevelgeneral">GCE O Level</NavDropdown.Item>
                                <NavDropdown.Item href="/download/pastpapers/alevelgeneral">GCE A Level</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/download/pastpapers/others">Others</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav>                            
                            <Nav.Link href="/books">Books</Nav.Link>
                            <Nav.Link eventKey={2} href="/olympiad">Olympiads</Nav.Link>                          
                            
                        </Nav>
                </Navbar.Collapse>

                <AuthenticatedTemplate>
                    
                    <div className="justify-content-end">
                        
                        <DropdownButton
                            variant="warning"
                            drop="down"
                            title={username ? username + '!': 'Unknown'}
                        >
                            <Dropdown.Item as="button" onClick={handleLogoutPopup}>
                                Sign out
                            </Dropdown.Item>
                            
                        </DropdownButton>
                    </div>
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                    <div className="justify-content-end">
                        <DropdownButton variant="secondary" className="ml-auto" drop="down" title="Sign In">
                            <Dropdown.Item as="button" onClick={handleLoginPopup}>
                                Sign in
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                </UnauthenticatedTemplate>
            </Navbar>
        </>
    )
}