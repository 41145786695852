export const msalConfig = {
    auth: {
      clientId: "1bee222b-9620-455f-a9bc-9245579e79b4",  // "ab7c970b-57ed-4726-87c9-7f3dca94d54d", //
      authority: "https://dolasspressb2c.b2clogin.com/tfp/dolasspressb2c.onmicrosoft.com/B2C_1_OgaBooks_Sign_Up_Policy", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      knownAuthorities:["dolasspressb2c.b2clogin.com"],
      //redirectUri: "http://localhost:3000/",
      redirectUri: "https://ogabook.com/"
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["https://dolasspressb2c.onmicrosoft.com/6c0e759f-e59a-4080-a3f9-a99117f0d221/Api.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };


  export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_OgaBooks_Sign_Up_Policy',
        forgotPassword: 'B2C_1_reset_v3',
        editProfile: 'B2C_1_edit_profile_v2',
    },
    authorities: {
        signUpSignIn: {
          authority: "https://dolasspressb2c.b2clogin.com/tfp/dolasspressb2c.onmicrosoft.com/B2C_1_OgaBooks_Sign_Up_Policy",
        },
        forgotPassword: {
          authority: "https://dolasspressb2c.b2clogin.com/tfp/dolasspressb2c.onmicrosoft.com/B2C_1_OgaBooks_Sign_Up_Policy",
        },
        editProfile: {
          authority: "https://dolasspressb2c.b2clogin.com/tfp/dolasspressb2c.onmicrosoft.com/B2C_1_OgaBooks_Sign_Up_Policy",
        },
    },
    authorityDomain: 'dolasspressb2c.b2clogin.com',
};