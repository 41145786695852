import React from "react";
import Lottie from "react-lottie-player";

import thumbsup from './asserts/50-60-percent-success-tick.json';


const style = {
    height: 300,
  };



const ValidateUserPayment = (props) => {

    /*const options = {
        animationData: thumbsup,
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRation: 'xMidYMid slice',
        },
      };

      const view = useLottie(options, style)*/

      

    return (
        <div>
            <div  style={{width: "50%", margin: "auto", padding: "15px"}}>
                <Lottie loop={true}
                        animationData={thumbsup}
                        play={true}
                        style={{ width: 80, height: 80}}
                />
            </div>
            <hr/>
            <div  style={{padding: "15px", textAlign: "center", }}>
                <p style={{fontSize: "0.8em", color: "green"}}><b>PAYMENT COMPLETED SUCCESSFULLY!!</b></p>
                <div style={{backgroundColor: "#4682b4", padding: "10px", textAlign: "left", color: "white", borderRadius: "0.5em"}}>
                    SUBJECT: <b>{props.title}</b><br/>
                    AMOUNT PAID: <b>{props.price} Fcfa</b><br/>
                    DURATION: <b>{props.duration} days</b>
                </div><br/>
                <button onClick={props.closeModalFxn} style={{backgroundColor: "dodgerblue", width: "80px", border: "10px solid dodgerblue", color: "white"}}>OK</button>
            </div>                       
            
        </div>
    )
}

export default ValidateUserPayment;