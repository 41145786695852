import React, { useEffect, useState } from "react";
import {getExaminationsByCategory} from '../MYAPI';
import Exams from './Exams';

function Concours(){
    const [examinations, setExaminations] = useState([]);
    const [loading, setLoading] = useState(true);
    
    
    useEffect(() => {
        setLoading(true);
        getExaminationsByCategory("concours", (res) => {
            setExaminations(res);
            setLoading(false);
        })
    }, []);
    const style = {
        display: "block"
    }
    return(
        <div>
            {loading ? <> Loading ...</> : <>

            {examinations && examinations.length < 1 ? <>No content</> : <div> 
            
            {examinations.map((examination) => {
                return(<div key={examination.categoryName}>
                    <div style={style}>                            
                        <Exams exam={examination}/>
                    </div>
                </div>)
            } )}

            </div> }

            </>}
            
        </div>
    );
}

export default Concours;