import React, { useEffect, useState } from "react";
import {Container, Row, Col, Form, Button, Spinner, FormText, InputGroup, FormControl} from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
import MyVerticallyCenteredModal from "../MyVerticallyCenteredModal";
import "./Olympiad.css";
import { useNavigate } from "react-router-dom";
import {getQuizStatus} from "../MYAPI"
import Modal from 'react-bootstrap/Modal';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { faL } from "@fortawesome/free-solid-svg-icons";

function NewOlympiad(){
    const [quizCode, setQuizcode] = useState("");
    const [isDisable, setIsDisable] = useState(false);
    //const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [isquizInstruction, setIsquizInstruction] = useState(false);
    const [isRightWrong, setIsRightWrong] = useState(false);
    const [hideStartBtn, setHideStartBtn] = useState(false);
    const [isLogin, setIsLogin] = useState(false);

    //Grabbing accessToken
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);

    const name = accounts[0] && accounts[0].name;
    
    function RequestAccessToken() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.accessToken);
            localStorage.setItem('accessToken', response.accessToken);
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                setAccessToken(response.accessToken);
            });
        });
    }

    useEffect(() => {
        console.log("....");
    }, [loading]);

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            
            setAccessToken(response.accessToken);
            localStorage.setItem('accessToken', response.accessToken);
            setIsLogin(true);
            
        }).catch((e) => {
            /*instance.acquireTokenPopup(request).then((response) => {
                setAccessToken(response.accessToken);
            });*/
            setIsLogin(false);
        });
    }, []);

    const handleClose = () => {
        setShow(false);
    };
    const handleParticipate = () => {
        if(!isLogin){
            setIsLogin(true);
            const request = {
                ...loginRequest,
                account: accounts[0]
            };
            instance.acquireTokenPopup(request).then((response) => {
                setAccessToken(response.accessToken);})
        }
        setLoading(true);
        setShow(true);
        navigate(`/olympiad/active/${quizCode}`)
        //console.clear();
        setShow(false);
        setLoading(false);
    };


    const handleShow = () => setShow(true);
    
    let navigate = useNavigate();

    async function handleJoinQuiz(event) {
        event.preventDefault();
        
        setLoading(true);
        //RequestAccessToken();

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken("Access token: ", response.accessToken);
            //call the backend
            getQuizStatus( quizCode, response.accessToken, (res) => {
            //success
            
            //1. Decompose the time factor return from res
            var startdate = new Date(res.writtenDate);
            var enddate = new Date();
            
            enddate.setMinutes(enddate.getMinutes() + res.durationInMinutes);
            
            const diffTime = enddate - startdate;

            setIsRightWrong(res.isRightWrong);

            var d = new Date(res.writtenDate);

            /*const diffInMins = Math.round(diffTime / (1000 * 60));
            const yy = d.getFullYear();
            const mm = d.getMonth();
            const dd = d.getDate();
            const hh = d.getHours();
            const mn = d.getMinutes();*/
            //console.log("quiz: ", res);
            if(res.status === 0){
                //alert("The Quiz is on going");
                localStorage.setItem('quiz', JSON.stringify(res));
                setIsquizInstruction(true);
                setHideStartBtn(false)
                setModalTitle(res.title);
                //setQuizTitle(res.title);
                setShow(true);

                //navigate(`/olympiad/active/${quizCode}/${res.title}`)

            }else if(res.status === 1){
                setModalTitle("Quiz has already been written");
                if(res.hasParticipated){
                    var txt = ". You can ONLY write a given quiz ONCE.";
                    setModalMessage( res.title  + " has already taken place! It was written on " + d.toLocaleString() + txt );
                }else{
                    setModalMessage(res.title  + " has already taken place! It was written on " + d.toLocaleString() );
                }
                

                setHideStartBtn(true);                
                handleShow(true);

            }else if(res.status === -1) {
                //alert("Quiz has already been written")                
                setModalTitle("Quiz is still to be written");
                setModalMessage(res.title + " is coming in the future " + d.toLocaleString());
                setHideStartBtn(true);
                handleShow(true);
            }

        }, (err) => {
            //error
            console.log("Error: ", err)
            alert("No Matching Quiz Found");
        });
       
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                setAccessToken(response.accessToken);
                //call the backend
        getQuizStatus( quizCode, response.accessToken, (res) => {
            //success
            
            //1. Decompose the time factor return from res
            var startdate = new Date(res.writtenDate);
            var enddate = new Date();
            
            enddate.setMinutes(enddate.getMinutes() + res.durationInMinutes);
            
            const diffTime = enddate - startdate;

            setIsRightWrong(res.isRightWrong);

            var d = new Date(res.writtenDate);

            /*const diffInMins = Math.round(diffTime / (1000 * 60));
            const yy = d.getFullYear();
            const mm = d.getMonth();
            const dd = d.getDate();
            const hh = d.getHours();
            const mn = d.getMinutes();*/
            console.log("quiz: ", res);
            if(res.status === 0){
                //alert("The Quiz is on going");
                localStorage.setItem('quiz', JSON.stringify(res));
                setIsquizInstruction(true);
                setModalTitle(res.title);
                //setQuizTitle(res.title);
                setShow(true);

                //navigate(`/olympiad/active/${quizCode}/${res.title}`)

            }else if(res.status === 1){
                setModalTitle("Quiz has already been written");
                if(res.hasParticipated){
                    var txt = ". You can ONLY write a given quiz ONCE.";
                    setModalMessage( res.title  + " has already taken place! It was written on " + d.toLocaleString() + txt );
                }else{
                    setModalMessage(res.title  + " has already taken place! It was written on " + d.toLocaleString() );
                }
                

                setHideStartBtn(true);
                
                handleShow(true);
            }else if(res.status === -1) {
                //alert("Quiz has already been written")                
                setModalTitle("Quiz is still to be written");
                setModalMessage(res.title + " is coming in the future " + d.toLocaleString());
                setHideStartBtn(true);
                handleShow(true);
            }

        }, (err) => {
            //error
            console.log("Error: ", err)
            alert("No Matching Quiz Found");
        });
       
            });
        });
        
        setLoading(false);
        //navigate(`/olympiad/active/${quizCode}/${title}`);gTdhbghW
    }

    function handleOnChange(e){
        setQuizcode( e.target.value);
        setIsDisable(false);
        
    }
   
    function handleAdvertClick(){

        if(quizCode.length < 3){            
            setShow(true);
        }else{

        }
    }

    return(
        <>
        <div style={{fontSize: "0.7em"}}>
            <Row className="justify-content-md-center center-div">
                <Col lg={12} xl={6}>
                    <div style={{"marginBottom": "2em"}}>
                        <h3 className="head1style">Premium Olympiad!<br/> Now available for everyone.</h3><br/><br/>
                        <h6>OgaBook Olympiad allows you to participate in divers competitions that usually come with prize award </h6>
                    </div>



                    <div style={{"marginBottom": "2em"}}>
                        <div style={{"marginBottom": "2em", "display": "flex", "justifyContent": "center" }}>
                            {loading? <>

                            <Spinner animation="border" variant="primary" />
                            <Spinner animation="grow" variant="secondary" />

                            </> : <></>}
                        </div>

                        <div>
                            <Form>
                                <Row>
                                    <Col xs={9}>
                                        <Form.Control size="lg" type="text" autoFocus placeholder="Enter a code e.g BFD7wYOW" value={quizCode} onChange={(e) =>  handleOnChange(e)} />                             
                                    </Col>
                                    <Col  xs={3}>
                                        <Button size="lg" variant="secondary" type="submit" disabled={isDisable} onClick={handleJoinQuiz}>Join</Button>
                                    </Col>  
                                    
                                </Row>
                            </Form>
                        </div>
                        
                         
                    </div>
                    <div style={{"marginBottom": "2em"}}>
                        <hr />                        
                        <h6><Link to="/about/olympiad">Learn more</Link> about OgaBook Olympiad </h6>
                    </div>
                </Col>

                <Col lg={12} xl={6}>
                    
                    <Carousel bsPrefix='carousel' variant="dark" >
                        <Carousel.Item interval={1000} onClick={handleAdvertClick}>
                            <img className="d-block w-100" src="https://ayosacademystorage.blob.core.windows.net/images/betaPoster1.jpeg" alt="First slide"  />
                            <Carousel.Caption>
                                <Button size="lg" variant="info">Explore More</Button>
                            <h3 style={{"visibility": "hidden"}}>BETA PREPA CONCOURS</h3>
                            <p style={{"visibility": "hidden"}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={10000} onClick={handleAdvertClick}>
                        <img className="d-block w-100" src="https://ayosacademystorage.blob.core.windows.net/images/betaFlyer1.jpeg" alt="First slide"  />
                            <Carousel.Caption>
                                <Button size="lg" variant="info">Explore More</Button>
                                <h3 style={{"visibility": "hidden"}}>Second slide label</h3>
                                <p style={{"visibility": "hidden"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={1000}  onClick={handleAdvertClick}>
                        <img className="d-block w-100" src="https://ayosacademystorage.blob.core.windows.net/images/betaPoster1.jpeg" alt="First slide"  />
                            <Carousel.Caption>
                                <Button size="lg" variant="info">Explore More</Button>
                                <h3 style={{"visibility": "hidden"}}>Third slide label</h3>
                                <p style={{"visibility": "hidden"}}>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                    
                </Col>
            </Row>

            {/*<MyVerticallyCenteredModal headertitle="This is Modal Header" title="Modal Title" description="Modal description" 
                                        show={modalShow}
                                        onHide={() => setModalShow(false)} /> */}

        </div>                                       

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isquizInstruction? <>
                    <div>
                        <h5 style={{color: "green"}}>Quiz is Ongoing</h5>
                        <b>Read the following instructions carefully!</b>
                        <ol>{isRightWrong? <>
                            <li><b>Right - Wrong: </b>You will be awarded <b>1 mark</b> for each correct question and <b>-1 mark</b> for each wrong answer.</li>
                        </> : <>
                            <li>All Questions carry Equal marks (1 mark each).</li>
                        </>}
                            
                            <li>Rank is calculated on the basis of <b>Marks scored and Time!</b></li>
                        </ol>
                    </div>
                    </> : <>
                    {modalMessage}
                    </>}
                     
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                {hideStartBtn ?<>Close</> : <>Ignore </>}
                </Button>
                {hideStartBtn ?<></> : <>
                    <Button variant="primary" onClick={handleParticipate}>
                        Start Now
                    </Button>
                </>}
                
                </Modal.Footer>
            </Modal>
        </>
        
        
    );

    
}

export default NewOlympiad;



