import React from 'react';
import { Link } from 'react-router-dom';

function Exam(props){
    const exam = props.exam;
    const myExamStyle = {
        border: "1px solid gray",
        borderRadius: "0.5em",
        margin: "8px"
    };
    const centeralign = {       
        textAlign: "center",
        padding: "10px",
    }
    let examDate = new Date();
    var edate = "";
    if(exam){
        const date = exam.examDate.split('T');
      
        const [y, m, d] = date[0].split('-');
        edate = d + "/" + m + "/" + y;
    }
    const disabled = {
        textDecoration:"none"
    }
    return(
        <div style={myExamStyle}>
            <Link to={`/examination-subject-list/${exam.id}`} style={disabled}>
                <div>
                    <img src={exam.imageUrl} height="200"  width="100%"/>
                    <div style={centeralign}>
                        <h3>{exam.examTitle}</h3>
                        <p>Date: {edate}</p>
                    </div>
                </div>
            </Link>            
        </div>
    );
}

export default Exam;