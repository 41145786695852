import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {Tabs, Tab, Container} from "react-bootstrap"
import AllOlympiad from "./AllOlympiad";
import NewOlympiad from "./NewOlympiad";
import "./Olympiad.css"
import {GetAllOlympiadsDtos} from '../MYAPI'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useNavigate } from 'react-router-dom';
import OlympiadResults from "./OlympiadResults";
import { faScaleUnbalanced } from "@fortawesome/free-solid-svg-icons";


function Olympiad(){
    const [pastOlympiads, setPastOlympiads] = useState([]);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const [allOlympics, setAllOlympics] = useState(true)
    const [resultsOlympics, setResultOlympics] = useState(false);
    const [contestsOlypics, setContestsOlympics] = useState(false);
    

    const { instance, accounts } = useMsal();  

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        if(accounts.length > 0){
            const request = {
                ...loginRequest, 
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then((response) => {
                GetAllOlympiadsDtos(response.accessToken, (res) => {
                    //console.log("Olympiads: ", response);
                    setPastOlympiads(res)
                    setLoading(false);
                }).catch((error) => {
                    //console.log("Error: ", error)
                })
            });
        }else{

            GetAllOlympiadsDtos("", (resp) => {
                //console.log("Olympiads: ", response);
                setPastOlympiads(resp)
                setLoading(false);
            }).catch((error) => {
                //console.log("Error: ", error)
            })
        }
    }, [])

    function reLoadData(){
        setLoading(true);
        if(accounts.length > 0){
            const request = {
                ...loginRequest, 
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then((response) => {
                GetAllOlympiadsDtos(response.accessToken, (res) => {
                    //console.log("Olympiads: ", response);
                    setPastOlympiads(res)
                    setLoading(false);
                }).catch((error) => {
                    //console.log("Error: ", error)
                })
            });
        }else{
            GetAllOlympiadsDtos( (response) => {
                //console.log("Olympiads: ", response);
                setPastOlympiads(response)
                setLoading(false);
            }).catch((error) => {
                //console.log("Error: ", error)
            })
        }
    }

    function handleLoadOlympiadMcqs(olympiad){
        navigate(`/pastpaper/mcq/${olympiad.id}/${olympiad.olympiadTitle}`)
        //console.log("Olympiad: ", olympiad)
    }

    function handleOlympics(key){
       
        if(key === "all" || key == "results"){
            setLoading(true);
            setAllOlympics(true);
            if(pastOlympiads && pastOlympiads.length < 1){
                reLoadData()
            }            
            setContestsOlympics(false);
            setResultOlympics(false);
            setLoading(false);
        }
        
        if(key == "contest"){
            setLoading(true);
            setAllOlympics(false);
            setContestsOlympics(true);
            setResultOlympics(false)
            setLoading(false);
        }
       
    }

    function handleLoadOlympiadResults(olympiad){
        console.log("Olympiad results: ", olympiad)
        navigate(`/olympiads/student-results/${olympiad.id}`)
    }
    return(
        <div style={{maxWidth: "760px", margin: "auto", backgroundColor: "whitesmoke", padding: "0 10px 10px 10px"}}>
            <Tabs defaultActiveKey="contest" id="uncontrolled-tab-example" className="mb-3"onSelect={handleOlympics}>

                <Tab eventKey="all" title="PAST CONTESTS" onSelect={handleOlympics} >
                    {allOlympics === true && resultsOlympics === false && contestsOlypics === false ? <>
                        {loading? <>
                        <div style={{width: "50%", margin: "auto"}}>
                            
                            <Spinner animation="border" variant="primary" >Please wait a while</Spinner>
                        </div>
                        </>: <>
                            <AllOlympiad olympiads={pastOlympiads} handleLoadOlympiadMcqs={handleLoadOlympiadMcqs}/>
                        </>}
                    </>: <>
                    
                    </>}
                    
                    
                </Tab>
                <Tab eventKey="results" title="RESULTS">
                    {(allOlympics === true || resultsOlympics === true) && contestsOlypics === false ? <>
                        {loading? <>
                        <div style={{width: "50%", margin: "auto"}}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                        </>: <>
                            <OlympiadResults olympiads={pastOlympiads} handleLoadOlympiadResults={handleLoadOlympiadResults} />
                        </>}
                    </>: <>
                    
                    </>}
                </Tab>
                <Tab eventKey="contest" title="CONTESTS..." >
                    {allOlympics === false && resultsOlympics === false && contestsOlypics === true ? <>
                        {loading? <>
                        <div style={{width: "50%", margin: "auto"}}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                        </>: <>
                            
                        </>}
                    </>: <>
                    
                    </>}

                 <NewOlympiad />
                </Tab>
            </Tabs>
        </div>
    );
}

export default Olympiad;