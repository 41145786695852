import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import {getSubjectDtosForGivenExamId} from '../MYAPI';
import ExamSubjectCard from "./ExamSubjectCard";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useNavigate } from 'react-router-dom';
import { addSubjectToDashboard } from "../MYAPI";


function ExamSubjects(){
    const [loading, setLoading] = useState(false);
    const [subjectDtos, setSubjectDtos] = useState([]);

    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);

    let {examId} = useParams();
    const navigate = useNavigate();

    const name = accounts[0] && accounts[0].name;

    useEffect(() => {
       setLoading(true); 
       getSubjectDtosForGivenExamId(examId, (res) => {
        //let notEnrolledSubjects = res.filter(x => x.isNotEnroll === true )
        
        setSubjectDtos(res);
        setLoading(false)
       })

    }, [] );

    function showLoading(subjectId){
        for(var i = 0; i<subjectDtos.length; i++){
            if(subjectDtos[i].subjectId == subjectId){
                subjectDtos[i].isLoading = true;
            }
        }
    }

    
    function EnrollmeInThisSubject(subjectId){
        showLoading(subjectId); 
        setLoading(true);

        if(name){
            //you are login ---> request tokens silently
            const request = {
                ...loginRequest, 
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then((response) => {
                
                if(response.accessToken != null){    
                    addSubjectToDashboard(subjectId, response.accessToken, (res) => {
                        setSubjectDtos(res);
                        
                        setLoading(false);setLoading(false);
                        //1.1 Notify a successful enrollment using a popup
    
                        //2. Navigate to Dashboard
                        navigate(`/dashboard`)
                        
                    } )
                }else{
                   // console.log("No access Token provided")
                }
                
            })
            
        }else{
            //1.) Ask user to login ----> you are not login
            instance.loginPopup(loginRequest).then((resp) => {
                //console.log("Observing what the login value is: ", resp)
                addSubjectToDashboard(subjectId, resp.accessToken, (res) => {
                    setSubjectDtos(res);
                    
                    setLoading(false);setLoading(false);
                    //1.1 Notify a successful enrollment using a popup

                    //2. Navigate to Dashboard
                    navigate(`/dashboard`)
                    
                } )

            }).catch(e => {
                console.error(e);
            });

            
        }

        
        //console.log("Name: ", name);
        
    }

    useEffect(() => {

    }, [subjectDtos])

    const displayStyle = {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent:"center",
      };

    const style = {
        display: "block"
    }
    return(
        <div>
            {subjectDtos && subjectDtos.length > 0 ? <>
            <div style={displayStyle}>
                
                {subjectDtos.map((subjectdto) => {
                    return(
                        <div key={subjectdto.subjectId} style={style}>
                            <ExamSubjectCard subjectdto={subjectdto} EnrollmeInThisSubjectFn={EnrollmeInThisSubject} isFromDownload={false} title='Enroll Now (Free)'   />
                        </div>
                    )
                })}
            </div>
            
            </>:<> <div style={{display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center"}}>  You've not yet enrolled in any subject. Please go to <b>Explore &gt; Examination and Enroll</b>  </div></>}
        </div>
    )
}

export default ExamSubjects;