import React from "react";

function About(){


    return(
        <div>
            <h1>I am About page</h1>
        </div>
    );
}

export default About;