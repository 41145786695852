import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {GetPriceAwardPageDtos} from '../MYAPI'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Moment from 'react-moment';
import { Button, Modal } from "react-bootstrap";
import {PostPriceAwardPageDtos} from '../MYAPI';
import Spinner from 'react-bootstrap/Spinner';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPen} from '@fortawesome/free-solid-svg-icons'

function OlympiadWinner(){
    const [loading, setLoading] = useState(false);
    const [winner, setWinner] = useState(null);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [sendingComment, setSendingComent] = useState(false);
    const [comments, setComments] = useState([]);

    let sendIcon = <FontAwesomeIcon style={{color: "white", fontSize: "1.1em" }} icon={faPen}  />
    
    const { instance, accounts } = useMsal();  

    useEffect(() => {
        setLoading(true);
        if(accounts.length > 0){
            const request = {
                ...loginRequest, 
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then((response) => {
                GetPriceAwardPageDtos(pastpaperId, userId, response.accessToken, (res) => {
                    //console.log("Olympiads: ", response);
                    setWinner(res);
                    setComments(res.awardComments);
                    setLoading(false);
                }).catch((error) => {
                    //console.log("Error: ", error)
                })
            });
        }else{
            GetPriceAwardPageDtos(pastpaperId, userId,  (response) => {
                //console.log("Olympiads: ", response);
                setWinner(response)
                setComments(response.awardComments);
                setLoading(false);
            }).catch((error) => {
                //console.log("Error: ", error)
            })
        }
    }, [])

    function handleClose(){
        setShow(false);
    }
    function handleShowModal(){
        setMessage('')
        setShow(true);
    }
    function handleFocus(){

    }
    const handleTxtAreaChange = (event) => {
        //event.preventDefault()
        setMessage(event.target.value);
        //console.log("State Value", message);
        
    }
    function handleSendComment(){
        if(message && message.length > 1){
            setSendingComent(true)
        if(accounts.length > 0){
            const request = {
                ...loginRequest, 
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then((response) => {
                PostPriceAwardPageDtos(message, response.accessToken, (res) => {
                    //console.log("Olympiads: ", response);
                    setComments(res);                   
                    console.log("res: ", res);
                    setLoading(false);
                }).catch((error) => {
                    //console.log("Error: ", error)
                })
            });
        }else{
            PostPriceAwardPageDtos(message,  (res) => {
                //console.log("Olympiads: ", response);
                setComments(res);    
                setLoading(false);
            }).catch((error) => {
                //console.log("Error: ", error)
            })
        }
        setSendingComent(false)
        //alert("Forwarded to backend: " + message)
        setShow(false);
        }
        
    }
    let {pastpaperId, userId} = useParams();
    return(
        <div style={{maxWidth: "760px", margin: "auto"}}>
            {loading? <>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Spinner animation="border" variant="primary" />
                </div>
            </> : <></>}
            {comments && comments && comments.length > 0? <>
                
                <div style={{fontSize: "0.9em", backgroundColor: "whitesmoke", marginBottom: "15px"}}>
                    <img src={winner.imageUrl} height="300"  width="100%" />
                    <div style={{padding: "10px"}}>
                        <b>{winner.studentName} from {winner.school} on <Moment  format="DD/MM/YYYY">{winner.awardDate}</Moment> </b>
                        <p style={{fontSize: "0.8em"}}><i>{winner.description}</i></p>
                    </div>                
                </div>

                {comments.map((comment) => {
                    return(
                    <div key={comment.date} >
                        <div style={{display: "flex", alignContent: "center", borderBottom: "1px solid lightgray", padding: "6px" }}>
                            <div style={{width: "50px", height: "50px", backgroundColor: "dodgerblue", borderRadius:"50%", margin: "6px", display: "flex",  justifyContent:"center", alignItems: "center", color: "white", fontWeight:"bold"}}>
                                <div>{comment.userName.slice(0,1)}</div>
                            </div>

                            <div style={{fontSize: "0.8em"}}>
                            <b>{comment.userName}</b> <span style={{fontSize: "0.7em"}}>{comment.duration}</span><br/>
                            {comment.message}
                            </div>
                        
                        </div>
                        
                    </div>
                    )
                })}
                
                <Modal show={show} onHide={handleClose}>
       
                <Modal.Body>

                    <div style={{ width: "100%", }}>
                        <textarea type="textarea" required 
                        id="message"
                        name="message"
                        value={message}
                        rows={6}
                        placeholder=''
                        onFocus={handleFocus}
                        onChange={handleTxtAreaChange}
                        style={{width: "100%", padding: "10px", backgroundColor: "#f8f8f8", border: "2px solid #ccc", borderRadius: "6px", fontSize: "12"}} />
                    </div>
                    {sendingComment? <>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </>: <></>}
                                
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Ignore
                    </Button>
                    <Button variant="primary" onClick={handleSendComment}>
                        Send
                    </Button>
                    </Modal.Footer>
                </Modal>

            </>: <></>}
            <div style={{ position: "fixed", bottom: "0", right: "0"}}>
            <div onClick={handleShowModal} style={{borderRadius: "50%", width: "40px", height: "40px", margin: "0 20px 20px 0", cursor: "pointer", backgroundColor: "dodgerblue", display: "flex", justifyContent:"center", alignItems:"center", alignContent: "center", justifyItems: "center"}}>
                <div>{sendIcon}</div>
                </div>
            </div>

            <div style={{marginBottom: "150px",}}>
               
                {/**
                 * Send your reaction Here
                 */}
            </div>

            
        </div>
    )
}

export default OlympiadWinner;
