
import { loginRequest } from "./authConfig";

import {useMsal} from "@azure/msal-react";

//const appUrl = "https://localhost:44395";
const appUrl = "https://etoederick-001-site2.htempurl.com";

export async function getQuizUsingCode(quizCode, token, callback, errorcallback){
    //console.clear();
    //console.log("Access Token from getQuizUsingCode: ",token);

    await fetch(`${appUrl}/api/Olympiads/${quizCode}`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
    .then(res => {
        if(callback != null){
            console.log('get_quiz res: ', res)
            callback(res);
        }
    }).catch(err => {
        // catch error
        if(errorcallback != null){
            errorcallback(err);
        }
        })

}

export async function getQuizStatus(quizCode, token, callback, errorcallback){
    //const token = GetAccessToken();
    //console.clear();
    //console.log("from getQuizStatus - Access Token: ", token);

    await fetch(`${appUrl}/api/Olympiads/?quizcode=${quizCode}`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
        'mode': 'no-cors',
        'Access-Control-Allow-Origin':'*'
     }
    }).then(response => response.json())
    .then(res => {
        if(callback != null){
            console.log("quiz status: ", res)
            callback(res);
        }
    }).catch(err => {
        // catch error
        if(errorcallback != null){
            errorcallback(err);
        }
        })
}

export async function sendQuizSolutionsToBackEnd(subjectId,pastpaperId, pastpaperNumber, topicNumber,questionPosition,paperYear, answerStatus, accessToken, callback, errorcallback){
    const token = accessToken;

    await fetch(`${appUrl}/api/UserProgressions/${subjectId}?pastpaperId=${pastpaperId}&pastpaperNumber=${pastpaperNumber}&topicNumber=${topicNumber}&questionPosition=${questionPosition}&paperYear=${paperYear}&answerStatus=${answerStatus}`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
    .then(res => {
        if(callback != null){
            callback(res);
        }
    }).catch(err => {
        // catch error
        if(errorcallback != null){
            errorcallback(err);
        }
        })
}

export async function sendUserQuizToBackEnd(pastpaperId, score, accessToken, callback, errorcallback){
    const token = accessToken;

    await fetch(`${appUrl}/api/UserQuiz/${pastpaperId}?score=${score}`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
    .then(res => {
        if(callback != null){
            callback(res);
        }
    }).catch(err => {
        // catch error
        if(errorcallback != null){
            errorcallback(err);
        }
        })

}

export async function getExaminationsByCategory(examCategory, callback, errorcallback){
    //console.clear();
    //console.log("Access Token from getQuizUsingCode: ",token);

    await fetch(`${appUrl}/api/Explores/?categoryType=${examCategory}`, {
        //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
    .then(res => {
        if(callback != null){
            callback(res);
            
        }
    }).catch(err => {
        // catch error
        if(errorcallback != null){
            errorcallback(err);
        }
        })

}

export async function getSubjectDtosForGivenExamId(examId, callback, errorcallback){
    await fetch(`${appUrl}/api/Examinationclient/${examId}`, {
        //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
    .then(res => {
        if(callback != null){
            for(var i = 0; i < res.length; i++){
                res[i].isLoading = false;
            }
            
            callback(res);
            //console.clear();
            //console.log("access Token: ", token);
            //console.log("subjectdtos: ", res)            
        }
    }).catch(err => {
        // catch error
        if(errorcallback != null){
            errorcallback(err);
        }
        })
}

//SubjectEnrollments/{SubjectId}
export async function addSubjectToDashboard(subjectId, accessToken, callback, errorcallback){
    const token = accessToken;
    
    await fetch(`${appUrl}/api/SubjectEnrollments/${subjectId}`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
    .then(res => {
        if(callback != null){
            
            for(var i = 0; i < res.length; i++){
                res[i].isLoading = false;
            }
            
            callback(res);
            //console.log("addSubjectToEnrollments --> subjectdtos: ", res);
        }
    }).catch(err => {
        // catch error
        if(errorcallback != null){
            errorcallback(err);
        }
        })
}

//SubjectEnrollments/{SubjectId}

export async function getUserSubjectEnrollments(accessToken, usrId, callback, errorcallback){
    const token = accessToken;
    //console.log("Access Token: ", accessToken);
    
    await fetch(`${appUrl}/api/SubjectEnrollments?userid=${usrId}`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
    .then(res => {
        if(callback != null){
            for(var i = 0; i < res.length; i++){
                res[i].isLoading = false;
            }
            
            callback(res);
            //console.log("My Enrollments --> subjectdtos: ", res);
        }
    }).catch(err => {
        // catch error
        if(errorcallback != null){
            errorcallback(err);
        }
        })
}


export async function UnEnrollment(subjectId, accessToken, callback, errorcallback){
    const token = accessToken;
    const url = `/api/SubjectEnrollments/${subjectId}`
    await fetch(`${appUrl}${url}`, {
        method: 'DELETE',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
    .then(res => {
        if(callback != null){
            for(var i = 0; i < res.length; i++){
                res[i].isLoading = false;
            }
            
            callback(res);
            //console.log("My Enrollments --> subjectdtos: ", res);
        }
    }).catch(err => {
        // catch error
        if(errorcallback != null){
            errorcallback(err);
        }
        })
}

export async function GetPaperOnePastPapers(subjectId, accessToken, usrId, callback, errorcallback){
    //console.log("subjectId>: ", subjectId)
    //console.log("accessToken: ", accessToken)
    const token = accessToken;
    
    const url = `/api/PaperOnes/${subjectId}?userId=${usrId}`
    await fetch(`${appUrl}${url}`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    })
        .then(response => response.json())
        .then(res => {
        if(callback != null){
            for(var i = 0; i < res.length; i++){
                res[i].isLoading = false;
            }
            //console.log(res);
            callback(res);
   
        }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}


export async function GetMcqsForGivenPastPaper(pastpaperId, accessToken, userId, callback, errorcallback){
    const token = accessToken;
    const url = `/api/McqPastPapers/${pastpaperId}?userId=${userId}`
    await fetch(`${appUrl}${url}`, {
        method: 'GET',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
    .then(res => {
        if(callback != null){
            for(var i = 0; i < res.length; i++){
                res[i].isLoading = false;
                
            }
            //console.log("mcqs: ", res)
            callback(res);
            //console.log("My Enrollments --> subjectdtos: ", res);
        }
    }).catch(err => {
        // catch error
        if(errorcallback != null){
            errorcallback(err);
        }
        })
}

///MobileMcqReports/{mcqReport.McqId}?pastPaperId={mcqReport.PastPaperId}&subjectId={mcqReport.SubjectId}&report={mcqReport.Report}&questionPosition={mcqReport.QuestionPosition}

export async function ForwardUserMCQReport(mcqId, pastPaperId, subjectId, reportText, questionPosition, accessToken, callback, errorcallback){
    const token = accessToken;
    //console.clear();
    //console.log("mcq: ", mcqId, "pastpaperI: ". pastPaperId, "subjectId: ", subjectId, "message: ", reportText, "position: " );
    const url = `/api/MobileMcqReports/${mcqId}?pastPaperId=${pastPaperId}&subjectId=${subjectId}&report=${reportText}&questionPosition=${questionPosition}`
    await fetch(`${appUrl}${url}`, {
        method: 'POST',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
    .then(res => {
        if(callback != null){
            for(var i = 0; i < res.length; i++){
                res[i].isLoading = false;
            }
            
            callback(res);
            //console.log("My Enrollments --> subjectdtos: ", res);
        }
    }).catch(err => {
        // catch error
        if(errorcallback != null){
            errorcallback(err);
        }
        })
}

export async function ForwardUserQuizScore(pastPaperId, score,  answersCollection, accessToken, callback, errorcallback){
    const token = accessToken;
    //console.clear();
    //console.log("mcq: ", mcqId, "pastpaperI: ". pastPaperId, "subjectId: ", subjectId, "message: ", reportText, "position: " );
    const url = `${appUrl}/api/UserProgressions/${pastPaperId}&score=${score}`
    //const localUrl = `https://localhost:44395/api/UserProgressions/${pastPaperId}?score=${score}`;
    
    await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(answersCollection)
    }).then(response => response.json()) 
        .then(res => {
            if(callback != null){
                
                callback(res);
                //console.log("My Enrollments --> subjectdtos: ", res);
            }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}

//Payments/{subjectId}?price={price}&duration={duration}&phone={phoneNumber}
export async function ForwardSubjectPayment(subjectId, price, duration, phoneNumber, accessToken, callback, errorcallback){
    const token = accessToken;
    //console.clear();
    //console.log("access Token: ", accessToken)
    console.log("subjectId: ", subjectId, "price: ", price, "duration: ", duration, "phone: ", phoneNumber)
    const url = `${appUrl}/api/Payments/${subjectId}?price=${price}&duration=${duration}&phone=${phoneNumber}`;
    //const localUrl = `https://localhost:44395/api/Payments/${subjectId}?price=${price}&duration=${duration}&phone=${phoneNumber}`;
    
    console.log("accessToken for payment: ", accessToken);

    await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        //body: JSON.stringify(answersCollection)
    }).then(response => response.json()) 
        .then(res => {
            if(callback != null){
                
                callback(res);
                console.log("Payment received: ", res);
            }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}

export async function GetSuccessfulPaymentsFromBackend(transaction_ref, accessToken, callback, errorcallback){
    const token = accessToken;
    const url = `${appUrl}/api/Payments/${transaction_ref}`;
    
    await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        //body: JSON.stringify(answersCollection)
    }).then(response => response.json()) 
        .then(res => {
            if(callback != null){
                
                callback(res);
                //console.log("Payment received: ", res);
            }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}

export async function UpdateSuccessFromBackEnd(accessToken, callback, errorcallback){
    const token = accessToken;
    
    //console.log("checking token availability: ", accessToken)
    
    localStorage.setItem('accessToken', accessToken);
    
    const url = `${appUrl}/api/Payments`;
    
    await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        //body: JSON.stringify(answersCollection)
    }).then(response => response.json()) 
        .then(res => {
            if(callback != null){
                
                callback(res);
                //console.log("Payment received: ", res);
            }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}

//GetSubjectTopics
export async function GetSubjectTopics(subjectId, accessToken, userId, callback, errorcallback){
    const token = accessToken;
    
    //console.log("checking token availability: ", accessToken)
    
    localStorage.setItem('accessToken', accessToken);
    
    const url = `${appUrl}/api/McqPastPapers/${subjectId}?userId=${userId}`;
    
    await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        //body: JSON.stringify(answersCollection)
    }).then(response => response.json()) 
        .then(res => {
            if(callback != null){
                
                callback(res);
                //console.log("Payment received: ", res);
            }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}

//GetMcqsForGivenTopicNumber
export async function GetMcqsForGivenTopicNumber(topicNumber, accessToken, userId, callback, errorcallback){
    const token = accessToken;         
    const url = `${appUrl}/api/McqPastPapers/${topicNumber}?userId=${userId}`;
    
    await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        //body: JSON.stringify(answersCollection)
    }).then(response => response.json()) 
        .then(res => {
            if(callback != null){
                
                callback(res);
                //console.log("Payment received: ", res);
            }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}

//GetHomePageInfo
export async function GetHomePageInfo(value, callback, errorcallback){
    
    const url = `${appUrl}/api/ExaminationClient`;
    
    await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
          }
        //body: JSON.stringify(answersCollection)
    }).then(response => response.json()) 
        .then(res => {
            if(callback != null){
                
                callback(res);
                //console.log("Method ===> Homepage info ", res);
            }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}

//GetAllOlympiadsDtos
export async function GetAllOlympiadsDtos(accessToken, callback, errorcallback){
    const token = accessToken;
    const url = `${appUrl}/api/Olympiads`;
    
    await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        //body: JSON.stringify(answersCollection)
    }).then(response => response.json()) 
        .then(res => {
            if(callback != null){
                
                callback(res);
                //console.log("Method ===> GetAllOlympiadsDtos ", res);
            }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}


//GetOlympiadQuizResultDtos
export async function GetOlympiadQuizResultDtos(pastpaperId, accessToken, callback, errorcallback){
    const token = accessToken;
    const url = `${appUrl}/api/Olympiads/${pastpaperId}`;
    
    await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        //body: JSON.stringify(answersCollection)
    }).then(response => response.json()) 
        .then(res => {
            if(callback != null){
                
                callback(res);
                //console.log("quiz results: ", res);
            }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}

//GetPriceAwardPageDtos
export async function GetPriceAwardPageDtos(pastpaperId, userid, accessToken, callback, errorcallback){
    const token = accessToken;
    const url = `${appUrl}/api/PastQuizAwards/${pastpaperId}?userId=${userid}`;
    
    await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        //body: JSON.stringify(answersCollection)
    }).then(response => response.json()) 
        .then(res => {
            if(callback != null){
                
                callback(res);
                console.log("quiz winner dto: ", res);
            }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}


//PostPriceAwardPageDtos

export async function PostPriceAwardPageDtos(message, accessToken, callback, errorcallback){
    const token = accessToken;
    const url = `${appUrl}/api/Quizawards/${message}`;
    
    await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        //body: JSON.stringify(answersCollection)
    }).then(response => response.json()) 
        .then(res => {
            if(callback != null){
                
                callback(res);
                console.log("quiz winner dto: ", res);
            }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}


//Gcepastpaperspdfdownloads
export async function GetGcepastpapersPdfDownloads(subjectId, callback, errorcallback){
    //const token = accessToken;
    const url = `${appUrl}/api/Gcepastpaperspdfdownloads/${subjectId}`;
    
    await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            //'Authorization': `Bearer ${token}`,
          }
        //body: JSON.stringify(answersCollection)
    }).then(response => response.json()) 
        .then(res => {
            if(callback != null){
                
                callback(res);
                //console.log("list of past downloads papers: ", res);
            }
        }).catch(err => {
            // catch error
            if(errorcallback != null){
                errorcallback(err);
            }
            })
}