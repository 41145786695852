import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";

function handleLogout(instance, props) {
    instance.logoutPopup().catch(e => {
        console.error(e);
    });
    //localStorage.removeItem('Name');
    props.setIsLogoutFn(false);
    localStorage.removeItem('accessToken');
}

/**https://ayosacademystorage.blob.core.windows.net/questions/103_2011_1_21_Justification.PNG
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = (props) => {
    const { instance} = useMsal();
    return (
        <Button variant="secondary" className="ml-auto" onClick={() => handleLogout(instance, props)}>Signout</Button>
    );
}