import { AuthenticatedTemplate, MsalProvider, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { PageLayout } from "./PageLayout";
//For ProfileContent
import { loginRequest, b2cPolicies } from "./authConfig";
import Button from "react-bootstrap/Button";
import { EventType } from '@azure/msal-browser';

//2nd layer addition
import { ProfileData } from "./ProfileData";
import { callMsGraph } from "./graph";
import { UsernamePasswordClient } from '@azure/msal-common';
import HomePage from './home/HomePage';
import About from './About';
import NewOlympiad from "./olympiad/NewOlympiad";
import Olympiad from "./olympiad/Olympiad";
import { Container } from "react-bootstrap";
import ActiveOlympiad from "./olympiad/ActiveOlympiad";
import { UserContext } from "./UserContext";
import { useMemo } from "react";
import {Routes, Route, Link } from "react-router-dom";
import Gce from "./examinantions/Gce";
import Concours from "./examinantions/Concours";
import ExamSubjects from "./examinantions/ExamSubjects";
import StudyIndex from "./study/StudyIndex";
import SubjectDetail from "./study/SubjectDetail";
import McqQuestons from "./studydashboard/McqQuestons";
import OlympiadResultStudentList from "./olympiad/OlympiadResultStudentList";
import OlympiadWinner from "./olympiad/OlympiadWinner";
import BookIndex from "./books/BookIndex";
import DownloadIndex from "./downloads/DownloadIndex";
import DownloadListPage from "./downloads/DownloadListPage";

//LATEST MODIFICATIONS
const Pages = () => {
  
  const { instance } = useMsal();

  useEffect(() => {
      const callbackId = instance.addEventCallback((event) => {
          if (
              (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
              event.payload.account
          ) {
              /**
               * For the purpose of setting an active account for UI update, we want to consider only the auth
               * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
               * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
               * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
               */
              if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
                  // retrieve the account from initial sing-in to the app
                  const originalSignInAccount = instance
                      .getAllAccounts()
                      .find(
                          (account) =>
                              account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                              account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                              account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
                      );

                  let signUpSignInFlowRequest = {
                      authority: b2cPolicies.authorities.signUpSignIn.authority,
                      account: originalSignInAccount,
                  };

                  // silently login again with the signUpSignIn policy
                  instance.ssoSilent(signUpSignInFlowRequest);
              }

              /**
               * Below we are checking if the user is returning from the reset password flow.
               * If so, we will ask the user to reauthenticate with their new password.
               * If you do not want this behavior and prefer your users to stay signed in instead,
               * you can replace the code below with the same pattern used for handling the return from
               * profile edit flow
               */
              if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.forgotPassword) {
                  let signUpSignInFlowRequest = {
                      authority: b2cPolicies.authorities.signUpSignIn.authority,
                  };
                  instance.loginRedirect(signUpSignInFlowRequest);
              }
          }

          if (event.eventType === EventType.LOGIN_FAILURE) {
              // Check for forgot password error
              // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
              if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                  const resetPasswordRequest = {
                      authority: b2cPolicies.authorities.forgotPassword.authority,
                      scopes: [],
                  };
                  instance.loginRedirect(resetPasswordRequest);
              }
          }
      });

      return () => {
          if (callbackId) {
              instance.removeEventCallback(callbackId);
          }
      };
      // eslint-disable-next-line
  }, [instance]);

  return (
    <Routes>              
            <Route path="/" element={ <HomePage /> } />
            <Route path="/auth" />
            <Route path="/about" element={<About />} />
            <Route path="/newolympiad" element={<NewOlympiad />} />
            <Route path="/olympiad" element={<Olympiad />} />
            <Route path="/olympiad/active/:quizCode" element={<ActiveOlympiad />} />            
            <Route path="/examination-gce" element={<Gce />} />
            <Route path="/examination-concours" element={<Concours />} />
            <Route path="/examination-subject-list/:examId"element={<ExamSubjects />} />            
            <Route path="/dashboard" element={<StudyIndex />} />
            <Route path="/subject-study-detail/:subjectId" element={<SubjectDetail />} />
            <Route path="/pastpaper/mcq/:pastpaperId/:paperTitle" element={<McqQuestons />} />
            <Route path="/topic/mcq/:topicId/:topicTitle" element={<McqQuestons />} />
            <Route path='/olympiads/student-results/:id' element={<OlympiadResultStudentList />} />
            <Route path='/olympiad/student-quiz-winner-award/:pastpaperId/:userId' element={<OlympiadWinner />} />
            <Route path='/books' element={<BookIndex />} />
            <Route path='/download/pastpapers/olevelgeneral' element={<DownloadIndex />}  />
            <Route path='/download/pastpapers/alevelgeneral' element={<DownloadIndex />}  />
            <Route path='/download/pastpapers/others' element={<DownloadIndex />}  />
            <Route path='/download/pastpapers-list/:subjectId' element={<DownloadListPage />} />


            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <h1>There's nothing here!</h1>
                </main>
              }
            />
            
                  

    </Routes>
  );
}

const App = ({instance}) => {
  const [userName, setUserName] = useState("");
  const [user, setUser] = useState(null);

  const value = useMemo(() => ({user, setUser}), [user, setUser])
  //console.clear();
  //console.log("username: ", value)
  //console.log("Instance: ", instance)

  return (
    <UserContext.Provider value={value}>
        <PageLayout>
          <Pages  />
        </PageLayout>
    </UserContext.Provider>
  );
}

{/*
<UserContext.Provider value={value}>
    <PageLayout>
      <Pages  />
    </PageLayout>
</UserContext.Provider>

*/}

{/* MOST RECENT REPLACEMENT
 <UserContext.Provider value={value}> 
          <PageLayout>
              <AuthenticatedTemplate>
               
                <p> {userName} You are signed in!</p>
                  <ProfileContent setUserNameFn={setUserName}/>
               
                {user}
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                  {localStorage.removeItem("Name")}
                  <p style={{"color": "red"}}>You are not signed in! Please sign in.</p>
              </UnauthenticatedTemplate>
          </PageLayout>

          //Routes removed here
        </UserContext.Provider>
 */}

{/*
<UserContext.Provider value={value}>
            
          </UserContext.Provider>
*/}


export default App;
/*
function ProfileContent(props) {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  const name = accounts[0] && accounts[0].name;

  function RequestProfileData() {
      const request = {
          ...loginRequest,
          account: accounts[0]
      };

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance.acquireTokenSilent(request).then((response) => {
        //console.log("Token response: ", response.accessToken);

        localStorage.setItem('accessToken', response.accessToken);

          callMsGraph(response.accessToken).then(response => setGraphData(response));
              
          props.setUserNameFn(name);

      }).catch((e) => {
        console.log("Detected Error: ", e);
          instance.acquireTokenPopup(request).then((response) => {
              callMsGraph(response.accessToken).then(response => setGraphData(response));
          });
      });
  }

  return (
      <>
          <h5 className="card-title">Welcome {name}</h5>
          {graphData ? 
              <ProfileData graphData={graphData} />
              :
              <Button variant="secondary" onClick={RequestProfileData}>Request Profile Information</Button>
          }
      </>
  );
};
*/
