import React, { useEffect, useState } from "react";
import './download.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCloudDownload, faTrash} from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

import { Spinner } from "react-bootstrap";

function DownloadCard(props){
    
    let downloadIcon = <FontAwesomeIcon style={{color: "lightgray", fontSize: "0.9em" }} icon={faCloudDownload}  />
    let downloadIconGray = <FontAwesomeIcon style={{color: "gray", fontSize: "0.5em" }} icon={faTrash}  />
    let {downloadDtos} = props;
    //console.log("downloadDtos: ", downloadDtos);
    
    return(
        <div>
            <div className="past-papers-by-year">                                
                    <div className="left-part-icon-design">
                        <b>YEAR</b>
                        <span style={{fontSize: "0.7em", fontWeight: "bold",color: "yellow"}}>June {props.paperYear}</span>  
                        {props.zipFileUrl && props.zipFileUrl.length > 0? <> 
                        
                           
                            <span style={{textAlign: "center", cursor: "pointer", fontSize: "2.2em"}} onClick={() => props.DownloadForWholePastPaper(props.zipFileUrl)}>{downloadIcon}</span>
                        
                        </>: <>
                            <span style={{textAlign: "center", cursor: "pointer", fontSize: "2.2em"}} >{downloadIconGray}</span>
                        </>} 
                                                
                        
                    </div>
                    
                    <div style={{width: "80%",  padding: "6px"}}>
                        {downloadDtos && downloadDtos.length > 0? <>
                            {downloadDtos.map((download) => {
                                return (
                                    <div key={download.id} >
                                        {download.url && download.url.length < 1 ? <>
                                            <div style={{textDecoration: "", backgroundColor: "white", display: "grid", alignItems: "center", margin: "8px", borderRadius: "8px"}} download>
                                            <div onClick={props.downloadPastpaper} className="download-div">
                                                <div className="first-div-to-icon"> {download.title} {download.loading? <div style={{display: "flex", justifyContent: "center", marginTop: "-24px"}}>
                                                <Spinner animation="border" variant="warning" />
                                                </div>:<></>} </div>
                                                <div className="icon-div">{downloadIcon}</div>
                                            </div>
                                        </div>
                                        </>: <>
                                        <div onClick={() => props.DownloadThisPastPaper(download)} style={{textDecoration: "", backgroundColor: "white", display: "grid", alignItems: "center", margin: "8px", borderRadius: "8px"}} download>
                                            <div onClick={props.downloadPastpaper} className="download-div">
                                                <div className="first-div-to-icon"> {download.title} {download.loading? <div style={{display: "flex", justifyContent: "center", marginTop: "-24px"}}>
                                                <Spinner animation="border" variant="warning" />
                                                </div>:<></>} </div>
                                                <div className="icon-div">{downloadIcon}</div>
                                            </div>
                                        </div>
                                        </>}
                                        
                                        
                                    </div>
                                    
                                )
                            })}
                        </>:<></>}
                    </div>
                    <span style={{clear: "both"}}></span>
                    
                </div>
        </div>
    )
}

export default DownloadCard;