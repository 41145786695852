
import React, { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import {loginRequest} from "../authConfig"
import { addSubjectToDashboard } from "../MYAPI";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';


function ExamSubjectCard(props){
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [subjects, setSubjects] = useState(null);

    const name = accounts[0] && accounts[0].name;

    const navigate = useNavigate();

    const subjectdto = props.subjectdto;
    const myStyle = {
        border: "1px solid gray",
        borderRadius: "0.5em",
        margin: "8px",
        width: "190px",
        display: "inline-block"
    }
    const titleStyle = {
        textAlign: "center",
        fontWeight: "bold",
        marginTop: "20px"
    }
    const btnStyle = {
        textDecoration:"none",
        backgroundColor: "orangered",
        padding: "10px",
        color: "white",
        borderRadius: "1.2em",
        fontWeight: "bold",
        border: "1px solid orangered"
    }

   /* function EnrollmeInThisSubject(subjectId){
                
        setLoading(true);

        if(name){
            //you are login ---> request tokens silently
            const request = {
                ...loginRequest, 
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then((response) => {
                
                if(response.accessToken != null){    
                    addSubjectToDashboard(subjectId, response.accessToken, (res) => {
                        setSubjects(res);
                        
                        setLoading(false);setLoading(false);
                        //1.1 Notify a successful enrollment using a popup
    
                        //2. Navigate to Dashboard
                        navigate(`/dashboard`)
                        
                    } )
                }else{
                   // console.log("No access Token provided")
                }
                
            })
            
        }else{
            //Ask user to login ----> you are not login
            instance.loginPopup(loginRequest).catch(e => {
                console.error(e);
            });
        }

        
        
    }*/
    return(
        
        <div style={myStyle}>
            <img src={subjectdto.imageUrl} height="160" width="190"/>
            <p style={titleStyle}>{subjectdto.subjectTitle}</p>
            {subjectdto.isLoading? <>

            <div style={{display: "flex", justifyContent: "center",alignItems: "center"}}>
                <Spinner animation="border" variant="primary" />
            </div>
                
            </>:<></>}
            <div style={{padding: "10px",display: "flex", justifyContent: "center "}}>
                {props.isFromDownload? <> 
                    <button style={btnStyle} onClick={() => props.LoadPastExamsDownloadablePapersFn(subjectdto.subjectId)}> {props.title} </button> 
                </>: <>
                <button style={btnStyle} onClick={() => props.EnrollmeInThisSubjectFn(subjectdto.subjectId)}> {props.title} </button> 
                </>}
                                            
            </div>
        </div>
        
    )
}

export default ExamSubjectCard;