import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {Tabs, Tab, Container} from "react-bootstrap"
import PaperOnepastpapersPage from '../studydashboard/PaperOnepastpapersPage';
import {GetPaperOnePastPapers} from "../MYAPI";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Lottie from 'react-lottie-player';
import business from '../asserts/73810-business-idea-animation.json';
import construction from '../asserts/32628-reading-education-career.json'
import constructionMen from '../asserts/77148-construction-men-at-work.json'
import PaperOneByTopicId from '../studydashboard/PaperOneByTopicIdPage';


function SubjectDetail(){
    const [loading, setLoading] = useState(false);
    const [paperOnes, setPaperOnes] = useState([]);
    const [subjectID, setSubjectId] = useState(0);
    const [status, setStatus] = useState('');
    const [count, setCount] = useState(0);
    const [correctCount, setCorrectCount] = useState(0);
    const [percentCoverage, setPercentCoverage] = useState(0);
    const [subjectTitle, setSubjectTitle] = useState('');

    const { instance, accounts } = useMsal();
    
    let { subjectId } = useParams();

    useEffect(() => {
        setLoading(true);
        setSubjectId(subjectId)       
        //console.log(subjectId);

        const accessToken = localStorage.getItem('accessToken');
        console.log("accessToken from localstorage: ", accessToken)

        const request = {
            ...loginRequest, 
            account: accounts[0]
        };
        //console.log('account: ', request.account);
        //console.clear();
        const resp = instance.acquireTokenSilent(request);
        console.log('resp: ', resp, 'request:', request);
        instance.acquireTokenSilent(request).then((response) => {
            GetPaperOnePastPapers(subjectId, response.accessToken,response.idTokenClaims.sub, (res)=> {
                setPaperOnes(res);
                //console.log("res: ", res)
                for(var i = 0; i<res.length; i++){
                    if(res[i].status.toLowerCase() == "paid"){
                        //Used in other components to request for payment
                        setStatus('Paid');
                        break;
                    }
                    if(res[i].status == "OBC"){
                        //Used in other components to request for payment
                        setStatus('OBC');
                        break;
                    }
                }

                setSubjectTitle(res[0].subjectTitle)

                setValues(res);

                setLoading(false);
            });
        })
        

    }, [])

    useEffect(() => {
        
    }, [paperOnes])

    function setValues(ps){
        //const ps = paperOnes;
        let q = 0;
        let ans = 0;
        for(var i = 0; i<ps.length; i++){
            q = q + ps[i].quantity;
            ans = ans + ps[i].correctAnsweredCount;
        }
        
        setCorrectCount(ans);
        setCount(q);
        var percent = (ans/q)*100;

        setPercentCoverage(percent)
    }

    

    return(
        <div style={{maxWidth: "760px", margin: "auto", backgroundColor: "whitesmoke"}}>
            <Tabs defaultActiveKey="p1list" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="p1list" title="MCQ P1" >
                    <div style={{border: "1px solid lightgray", padding: "10px", textAlign: "center", backgroundColor: "white"}}>
                        {percentCoverage.toFixed(2)}% Complete <b>{count - correctCount}/{count} MCQS</b> to go
                        <div style={{width: "100%", height: "4px", backgroundColor: "lightgray"}}></div>
                        <div style={{width: `${percentCoverage.toFixed(2)}%`, height: "6px", backgroundColor: "green", position: "relative", top: "-5px"}}></div>
                        <span>{correctCount}</span> : <span>{count - correctCount}</span>
                    </div>
                    <PaperOnepastpapersPage paperOnes={paperOnes} isLoading={loading}/>
                </Tab>
                <Tab eventKey="mqcbytopic" title="MCQ BY TOPICS">
                    
                    <PaperOneByTopicId subjectId={subjectID} status={status}  title={subjectTitle}/>

                </Tab>
                <Tab eventKey="p2" title="PAPER 2&3" >
                <Lottie loop={true}
                            play={true} animationData={construction}
                            style={{width: 200, height: 150 }}
                     />
                    <p>Under Construction!</p>
                </Tab>
                <Tab eventKey="tutorials" title="TUTORIALS" >
                <Lottie loop={true}
                            play={true} animationData={constructionMen}
                            style={{width: 200, height: 150 }}
                     />
                    <p>Under Construction!</p>
                </Tab>
            </Tabs>
        </div>
    )
}

export default SubjectDetail;