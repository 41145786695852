import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const root = ReactDOM.createRoot(document.getElementById('root'));

const msalInstance = new PublicClientApplication(msalConfig);

if (window.location.hash !== ''){
  console.log("hash found" + window.location.hash);
}
else{
  root.render(
    <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Routes>
          
            <Route path="*" element={ <App /> }>
            </Route>
        </Routes>
      </BrowserRouter>
    </MsalProvider>
    </React.StrictMode>,
    //document.getElementById('root')
    
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

{/*ORIGINAL CODE
<React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
*/}

{/* FROM STACKOVERFLOW
<React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <App /> }>
        </Route>
      </Routes>
    </BrowserRouter>
  </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
*/}